import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

const getTurnoById = async (idTurno, token, interfaz) => {
    try {
        const response = await axios.get(`${apiUrl}/api/plataforma_medica/turnos/turno/${idTurno}/${interfaz}`, { headers: { Authorization: `Bearer ${token}` } })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}

const updateTurno = async (idTurno, newData, token, interfaz) => {
    try {
        let response
        if (!newData.file) {
            response = await axios.put(`${apiUrl}/api/plataforma_medica/turnos/actualizar/${idTurno}/${interfaz}`, newData, { headers: { Authorization: `Bearer ${token}` } })
        } else {
            response = await axios.put(`${apiUrl}/api/plataforma_medica/turnos/actualizar/${idTurno}/${interfaz}`, newData, { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }, timeout: 500000 })
        }

        console.log("respuesta en act turno (pago): ", response)
        return response.data
    } catch (error) {
        console.log("error al: ", error)
        if (error.response) {
            return error.response.data
        }
    }
}

const solicTurnoProfServices = {
    getTurnoById,
    updateTurno
}

export { solicTurnoProfServices }