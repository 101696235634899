// servicio que trae del backend todos los turnos medicos existentes para un determinado paciente en sesion
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';  // declaracion de variable de entorno de la API

const getTurnosForPaciente = async (idPaciente, token) => {
    try {
        const todosTurnos = await axios.get(`${apiUrl}/api/plataforma_medica/turnos/turnosForUser/`, { headers: { Authorization: `Bearer ${token}` } });
        return todosTurnos.data;
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }

};

const turnosForPacienteServices = {
    getTurnosForPaciente
};

export { turnosForPacienteServices }