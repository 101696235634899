import React, { useState, useEffect } from 'react';
import { turnosForPacienteServices } from './services/turnosForPaciente.service.js';
import { useNavigate } from 'react-router-dom';
import logoPTM from '../../../images/newLogoPTM.jpeg'
import './GridPaciente.css'
import { SessionExpired } from '../../components/SessionExpired/SessionExpired.js';
import useIp from '../../PantallaInicial/components/IniciarSesion/hooks/useIp.js';
import { useEvento } from '../../../hooks/useEvento.js';

const GridPaciente = ({ usuarioEnSesion, token, setEstaEnSesion, setUsuarioEnSesion, setToken, setEstaEnAjustes, setEstaEnContacto, setEstaEnPregFrec }) => {
    const navigate = useNavigate()
    const [turnosDelPaciente, setTurnosDelPaciente] = useState([]) // Almaceno todos los turnos del paciente sin importar su estado
    const [tokenVencido, setTokenVencido] = useState(false)

    const ip = useIp()
    const { handleCampoLlego, campoLlego, crearE } = useEvento()

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerTurnosDePaciente = async () => {
        if (usuarioEnSesion) {
            const response = await turnosForPacienteServices.getTurnosForPaciente(usuarioEnSesion.usuarioComun.Id, token)
            if (response.error) {
                if (response.error === "TOKEN VENCIDO.") {
                    setTokenVencido(true);
                    return;
                }
            }
            setTurnosDelPaciente(response)
        }
    }

    // traigo dichos turnos del paciente que se encuentra logueado, sin importar su estado
    useEffect(() => {
        traerTurnosDePaciente()
    }, [usuarioEnSesion])

    // si el usuario pulsa el boton volver, lo dirige a la pantalla anterior en la que se encontraba
    const handleBack = () => {
        navigate("/bienvenida")
        crearE({ id_tipo: 80, id_resultado: 1, ip_user: ip, id_usuario: usuarioEnSesion.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion.usuarioComun ? usuarioEnSesion.Id : null }, token)
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className='col s12 center'>
                        <div className="logo-container center">
                            <img src={logoPTM} alt="Logo PTM" width="500" />
                        </div>
                        <h5 id="header-form-registro">MIS TURNOS</h5>
                    </div>

                    <div className='col s12 center'>
                        <button
                            className='btn waves-effect waves-light'
                            type="button"
                            onClick={handleBack}
                            style={{ marginBottom: '8px' }}
                        >
                            VOLVER
                        </button>
                    </div>

                    {/* SI HAY TURNOS, QUE MUESTRE LA TABLA, EN CASO CONTRARIO, QUE MUESTRE UN TEXTO QUE DIGA QUE NO HAY TURNOS */}
                    {turnosDelPaciente.error === undefined ? (
                        <div className="col s12 center-align" id='tablaTurnos'>
                            <table className="striped responsive-table" style={{ marginBottom: '100px' }}>
                                <thead>
                                    <tr>
                                        <th>FECHA</th>
                                        <th>HORA</th>
                                        <th>ESPECIALIDAD</th>
                                        <th>ESTADO</th>
                                        <th>MODALIDAD</th>
                                        <th>OFERTA</th>
                                        <th>CONFIRMAR</th>
                                        <th>VER DETALLE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {turnosDelPaciente.map((registro, index) => (
                                        <tr key={index}>
                                            <td style={{ fontWeight: "500" }}>{registro.Fecha}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Hora}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Especialidade.Nombre}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.EstadosTurno.Nombre}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Modalidad}</td>
                                            {/* EL BOTON VER OFERTA CONSISTE EN QUE LO REDIRIGE AL COMPONENTE OFERTA TURNO, Y SOLO SE DEBE DE HABILITAR ESTE BOTON SI EL ESTADO DEL TURNO ES ASIGNADO (7), ES DECIR, DESDE ACA EL PACIENTE ACEPTA/RECHAZA LA OFERTA DE TURNO */}
                                            <td>
                                                <button className='btn waves-effect waves-light' disabled={(registro.IdEstadoTurno !== 7)} onClick={() => navigate(`/ofertaTurno/${registro.IdTurno}`)}>
                                                    VER OFERTA
                                                </button>
                                            </td>

                                            {/* EL BOTON CONFIRMAR CONSISTE EN QUE LO REDIRIGE AL COMPONENTE DE CONFIRMACION DE TURNO LADO PACIENTE, Y DEBE SER HABILITADO SOLAMENTE SI EL ESTADO DEL TURNO ES CONFIRMADOPORPROFESIONAL (5),
                                    ES DECIR, DESDE ACA EL PACIENTE CONRFIRMA EL TURNO */}
                                            <td>
                                                <button className='btn waves-effect waves-light' disabled={(registro.IdEstadoTurno !== 5)} onClick={() => navigate(`/confirmacionTurnoPac/${registro.IdTurno}`)}>
                                                    CONFIRMAR
                                                </button>
                                            </td>

                                            {/* AÑADIR UN BOTON DE VER DETALLE, QUE FUNCIONE IGUAL QUE COMO ESTA EXPLICADO EN GRIDPROFESIONAL.JS */}
                                            <td>
                                                <button className='btn waves-effect waves-light' onClick={() => navigate(`/detalleTurno/${registro.IdTurno}`)}>
                                                    VER DETALLE
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (<div className="col s12 center-align"><h2>NO HAY TURNOS</h2></div>)}
                </>
            ) : (
                < SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}


        </>
    );
}

export { GridPaciente }