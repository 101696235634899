// servicio que trae del backend todos los turnos medicos existentes para un determinado doctor en sesion, dichos turnos solo fueron aceptados por el paciente, y el doctor debe de confirmarlos
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';  // declaracion de variable de entorno de la API

const getTurnosAceptadosForPaciente = async (idProf, token) => {
    try {
        const todosTurnos = await axios.get(`${apiUrl}/api/plataforma_medica/turnos/turnosAceptadosXPacienteByDoctor/`, { headers: { Authorization: `Bearer ${token}` } });
        return todosTurnos.data;
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
};

const turnosAceptadosForPacienteServices = {
    getTurnosAceptadosForPaciente
};

export { turnosAceptadosForPacienteServices }