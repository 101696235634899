import axios from "axios"
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000'; // declaracion de variable de entorno de la API

const getTurnosPacSalaEspera = async (token) => {
    try {
        const turnosTraidos = await axios.get(`${apiUrl}/api/plataforma_medica/turnos/enSalaEspera`, { headers: { Authorization: `Bearer ${token}` } });
        return turnosTraidos.data;
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}

const salaEsperaPacServices = {
    getTurnosPacSalaEspera,
}

export { salaEsperaPacServices }