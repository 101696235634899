import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import "./RecoverPassword.css";

const RecoverPassword = ({ apiUrl }) => {
    const navigate = useNavigate();
    const [usuario, setUsuario] = useState({})
    const [errorUsuario, setErrorUsuario] = useState(false);
    const [errorNuevaContrasena, setErrorNuevaContrasena] = useState('');

    // funcion que se ejecuta cuando el usuario pulsa en VOLVER
    const handleBack = () => {
        setUsuario({});
        setErrorNuevaContrasena('');
        setErrorUsuario(false)
        setShowNewPasswordForm(false)
        setEmail(false)
        setTelefono('')
        setNewPassword('')
        setConfirmNewPassword('')
        navigate("/")
    }

    // estado que me permite pasar a la creacion de una nueva contraseña
    const [showNewPasswordForm, setShowNewPasswordForm] = useState(false);
    // funcion que se ejecuta despues del que el usuario pulse continuar en la primera parte de esta pantalla (en donde ingresa email y telefono)
    const handleRecoverPasswordSubmit = async (e) => {
        e.preventDefault();
        const data = {
            email,
            telefono
        }
        const user = await axios.post(`${apiUrl}/api/plataforma_medica/usuarios/obtener/por/mailytelefono`, data) // busca si el usuairo existe en la base de datos
        if (user.data.error === undefined) { // si existe: 
            setUsuario(user.data);
            setShowNewPasswordForm(true);
            setErrorUsuario(false);
        } else { // si no existe:
            setErrorUsuario(true);
        }
    }

    // validar si el usuario ingreso un mail
    const [email, setEmail] = useState("");
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    // validacion del numero telefonico 
    const [telefono, setTelefono] = useState('');
    function handleTelefonoChange(event) {
        const regex = new RegExp(event.target.pattern);
        if (!regex.test(event.target.value)) {
            event.target.setCustomValidity('Ingresa un número de teléfono válido');
        } else {
            event.target.setCustomValidity('');
            setTelefono(event.target.value);
        }
    }

    // almaceno el password creado por el usuario
    const [newPassword, setNewPassword] = useState("");
    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    }

    // almaceno la confirmacion del password creado por el usuario
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const handleConfirmNewPasswordChange = (e) => {
        setConfirmNewPassword(e.target.value);
    }

    // funcion que se ejecuta cuando el usuariohace click en crear nueva contraseña
    const handleCreateNewPasswordSubmit = async (e) => {
        e.preventDefault();
        if (newPassword.length < 4 || newPassword.length > 15) { // validacion de longitud de contraseña
            setErrorNuevaContrasena('ERROR. Contraseña o muy corta o muy larga, intentá con otra contraseña')
            return;
        }

        // validando si el usuario ingreso la misma contraseña que tenia previamente guardada como contraseña
        if (newPassword === usuario.Contrasena) {
            setErrorNuevaContrasena('ERROR. La nueva contraseña no puede ser la misma que la anterior')
            return;
        }

        setErrorNuevaContrasena('')

        const data2 = {
            email,
            telefono,
            newPassword
        }
        await axios.put(`${apiUrl}/api/plataforma_medica/usuarios/actualizar/contrasena/`, data2)
        navigate('/'); // volver a la pantalla de inicio de la app
    }

    // renderizado del componente de recuperacion de contraseña
    return (
        <div className='container-center' style={{ height: "100vh" }}>
            <div className="row recover-container">
                <div className='col s12 center'>
                    <h3 className="recover-header grey darken-1 white-text">RECUPERAR CONTRASEÑA</h3>
                </div>
                {/* Si todavia no ingreso un mail y un numero de telefono,ademas de que aun no pulso el boton CONTINUAR, se renderiza el primer bloque, en  cuanto pulse continuar se renderizara el segundo bloque, en el ccual el usuario no podra crear la contraseña hasta que haya ingresado los 2 campos, y ademas lo que haya ingresado en ambos campos sea exactamente igual */}
                {!showNewPasswordForm ? (
                    <form className='col s12'>
                        <div className='row'>
                            <div className="col s12">
                                <label htmlFor="emailrecover" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Correo Electronico</label>
                                <input id="emailrecover" style={{ fontWeight: "bold" }} type="email" name="emailrecover" className="validate" value={email} onChange={handleEmailChange} required />
                            </div>

                            <div className="col s12">
                                <label htmlFor="telefonorecover" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Numero de Telefono</label>
                                <input type="text" id="telefonorecover" style={{ fontWeight: "bold" }} name="telefonorecover" className='validate' value={telefono} onChange={handleTelefonoChange} pattern="^(\+)?[0-9]{0,14}$" required />
                            </div>

                            {errorUsuario && (
                                <>
                                    <span className='error-message'>ERROR. NO existe el usuario</span>
                                    <br></br>
                                </>
                            )}

                            <div className='col s12 center'>
                                <button className="btn waves-effect waves-light amber darken-2" type="submit" name="action" onClick={handleRecoverPasswordSubmit} disabled={!email || !telefono}>RECUPERAR CONTRASEÑA
                                    <i className="material-icons right">send</i>
                                </button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <form className='col s12'>
                        <div className='row'>
                            <div className="col s12">
                                <label htmlFor="contrasenaNuevaCreacion" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Cree su Nueva Contraseña</label>
                                <input type="password" style={{ fontWeight: "bold" }} id="contrasenaNuevaCreacion" name="contrasenaNuevaCreacion" className='validate' value={newPassword} onChange={handleNewPasswordChange} required />
                            </div>

                            <div className="col s12">
                                <label htmlFor="confirmacionContrasenaNueva" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Repita su Nueva Contraseña</label>
                                <input type="password" style={{ fontWeight: "bold" }} id="confirmacionContrasenaNueva" name="confirmacionContrasenaNueva" className='validate' value={confirmNewPassword} onChange={handleConfirmNewPasswordChange} required />
                            </div>
                            {errorNuevaContrasena && (
                                <>
                                    <span className='error-message'>{errorNuevaContrasena}</span>
                                    <br></br>
                                </>
                            )}

                            <div className='col s12 center'>
                                <button className="btn waves-effect waves-light amber darken-2" type="button" name="action" disabled={!confirmNewPassword || !newPassword || confirmNewPassword !== newPassword} onClick={handleCreateNewPasswordSubmit}>CREAR NUEVA CONTRASEÑA
                                    <i className="material-icons right">send</i>
                                </button>
                            </div>
                        </div>
                    </form>
                )}
                <div className='col s12 center'>
                    <button
                        className='btn waves-effect waves-light'
                        type="button"
                        onClick={handleBack}
                        style={{ marginTop: "20px" }}
                    >
                        VOLVER
                    </button>
                </div>
            </div>
        </div>
    );
};

export { RecoverPassword }