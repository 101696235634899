// servicio que trae del backend todos los turnos medicos confirmados por ambas partes para un determinado doctor en sesion
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';  // declaracion de variable de entorno de la API

const getTurnosEnSalaEsperaForProfesional = async (idProf, token) => {
    try {
        const todosTurnos = await axios.get(`${apiUrl}/api/plataforma_medica/turnos/enSalaEsperaProf/${idProf}`, { headers: { Authorization: `Bearer ${token}` } });
        return todosTurnos.data;
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
};

const updateTurno = async (idTurno, data, token) => {
    try {
        const response = await axios.put(`${apiUrl}/api/plataforma_medica/turnos/actualizar/${idTurno}`, data, { headers: { Authorization: `Bearer ${token}` } })
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}


// si el turno es de especialidad PSICOLOGIA ADMISION
const getTurnoActualizado = async (idTurno, token) => {
    try {
        const response = await axios.get(`${apiUrl}/api/plataforma_medica/turnos/turno/${idTurno}/6`, { headers: { Authorization: `Bearer ${token}` } })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}


const turnosSalaEsperaForProfesionalServices = {
    getTurnosEnSalaEsperaForProfesional,
    updateTurno,
    getTurnoActualizado
};

export { turnosSalaEsperaForProfesionalServices }