import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './TurnosSinAsignar.css';
import { turnosAceptadosForProfesionalServices } from './services/turnosSinAsignar.service.js';
import logoPTM from '../../../images/newLogoPTM.jpeg'
import { SessionExpired } from '../../components/SessionExpired/SessionExpired.js';
import useIp from '../../PantallaInicial/components/IniciarSesion/hooks/useIp.js';
import { createEvento } from '../../../services/evento.service.js';
import { useEvento } from '../../../hooks/useEvento.js';

const TurnosSinAsignar = ({ usuarioEnSesion, token, setEstaEnSesion, setUsuarioEnSesion, setToken, setEstaEnAjustes, setEstaEnContacto, setEstaEnPregFrec }) => {
    const navigate = useNavigate()
    const [turnosAceptadosDelProfesional, setTurnosAceptadosDelProfesional] = useState([])
    const [tokenVencido, setTokenVencido] = useState(false)

    const ip = useIp()
    const { handleCampoLlego, campoLlego, crearE } = useEvento()

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerTurnosDeProfesional = async () => {
        if (usuarioEnSesion) {
            const response = await turnosAceptadosForProfesionalServices.getTurnosAceptadosForProfesional(usuarioEnSesion.Id, token)
            if (response.error) {
                if (response.error === "TOKEN VENCIDO.") {
                    setTokenVencido(true);
                    return;
                }
            }
            setTurnosAceptadosDelProfesional(response)
        }
    }

    // traer los turnos que el profesional acepto, pero que aun no asigno ni fecha, ni hora, ni demas 
    useEffect(() => {
        traerTurnosDeProfesional()
    }, [usuarioEnSesion])

    const handleBack = () => {
        navigate('/bienvenida')
        createEvento({ id_tipo: 96, id_resultado: 1, ip_user: ip }, token)
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className='col s12 center'>
                        <div className="logo-container center">
                            <img src={logoPTM} alt="Logo PTM" width="500" />
                        </div>
                        <h5 id="header-form-registro">MIS TURNOS ACEPTADOS</h5>
                    </div>

                    <div className='col s12 center'>
                        <button
                            className='btn waves-effect waves-light'
                            type="button"
                            onClick={handleBack}
                            style={{ marginBottom: '24px' }}
                        >
                            VOLVER
                        </button>
                    </div>

                    {turnosAceptadosDelProfesional.error === undefined ? (
                        <div className="col s12 center-align" id='tablaTurnos'>
                            <table className="striped responsive-table" style={{ marginBottom: '100px' }}>
                                <thead>
                                    <tr>
                                        <th>FECHA</th>
                                        <th>HORA</th>
                                        <th>ESPECIALIDAD</th>
                                        <th>ESTADO</th>
                                        <th>MODALIDAD</th>
                                        <th>ASIGNAR</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {turnosAceptadosDelProfesional.map((registro, index) => (
                                        <tr key={index}>
                                            <td style={{ fontWeight: "500" }}>{registro.Fecha}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Hora}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Especialidade.Nombre}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.EstadosTurno.Nombre}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Modalidad}</td>
                                            <td>
                                                <button className='btn waves-effect waves-light' disabled={(registro.IdEstadoTurno !== 3)} onClick={() => navigate(`/asignacionTurno/${registro.IdTurno}`)}>
                                                    ASIGNAR
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (<div className="col s12 center-align"><h2>NO HAY TURNOS</h2></div>)}
                </>
            ) : (
                < SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    );
};

export { TurnosSinAsignar }