import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

const getTurnosEnSalaEsperaUser = async (idUser, token) => {
    try {
        const response = await axios.get(`${apiUrl}/api/plataforma_medica/turnos/enSalaEsperaUser/`, { headers: { Authorization: `Bearer ${token}` } })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}

const salaEsperaPacServices = {
    getTurnosEnSalaEsperaUser
}

export {
    salaEsperaPacServices
}