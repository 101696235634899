import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logoPTM from "../../../images/newLogoPTM.jpeg"

const ConfirmarRegistro = ({ setEsMedico }) => {
    const navigate = useNavigate()
    const [pulsoCheckBox, setPulsoCheckBox] = useState(false)

    return (
        <>
            <div className='row'>
                <div className="logo-container center">
                    <img src={logoPTM} alt="Logo PTM" width="500" />
                </div>

                <div className='col s12 center'>
                    <h4 id="header-form-registro" className='center-align'>CONFIRMACIÓN DE REGISTRO</h4>
                </div>

                <div className='col s12 center'>
                    <h5 className='center-align'>Has completado exitosamente tu registro en DOCTORFY</h5>
                </div>

                <br></br>

                <div className='col s12 center' style={{ marginTop: "12px" }}>
                    <input type="checkbox" className="filled-in" id="check-registro" name="check-registro" onChange={() => setPulsoCheckBox(prevState => !prevState)} />
                    <label htmlFor="check-registro">Acepto los <a href="/assets/politicas.pdf" target='_blank'>Términos y Condiciones</a></label>
                </div>

                <br></br>

                <div className='col s12 center'>
                    <button
                        className="btn waves-effect waves-light"
                        onClick={() => { setEsMedico(null); navigate("/inicioSesion") }}
                        disabled={!pulsoCheckBox}
                        style={{ marginTop: "12px" }}
                    >
                        INICIAR SESION
                        <i className="material-icons right">send</i>
                    </button>
                </div>
            </div>
        </>
    )
}

export { ConfirmarRegistro }
