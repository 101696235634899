import React, { useState, useEffect } from 'react';
import { ciudadesService } from '../../Registro/services/ciudades.service.js';

const useLugares = () => {
    // GESTION DEL PAIS, ESTADO, Y CIUDADES ELEGIDAS POR EL USUARIO (YA SEA PACIENTE O PROFESIONAL):
    const [paises, setPaises] = useState([]);
    const [estadosPaisEspecifico, setEstadosPaisEspecifico] = useState([]);
    const [ciudadesEstadoEspecifico, setCiudadesEstadoEspecifico] = useState([])
    const [paisElegido, setPaisElegido] = useState(0);
    const [estadoElegido, setEstadoElegido] = useState(0);
    const [ciudadElegida, setCiudadElegida] = useState(0);


    // cuando se renderice el formulairo por primera vez, traer todos los paises:
    useEffect(() => {
        const traerPaises = async () => {
            const paisesTraidos = await ciudadesService.getAllPaises();
            setPaises(paisesTraidos);
        }
        traerPaises();
    }, []);

    useEffect(() => {
        if (paisElegido !== 0) {
            const traerEstadosDePais = async () => {
                const estadosTraidos = await ciudadesService.getEstadosByPais(paisElegido);
                const estadoPresente = estadosTraidos.some(estado => estado.Id === estadoElegido);
                if (!estadoPresente) {
                    setEstadoElegido(0);
                }
                setEstadosPaisEspecifico(estadosTraidos);
            }
            traerEstadosDePais();
        }
    }, [paisElegido]);

    useEffect(() => {
        if (estadoElegido !== 0) {
            const traerCiudadesDeEstado = async () => {
                const ciudadesTraidas = await ciudadesService.getCiudadesByEstado(estadoElegido);
                const ciudadPresente = ciudadesTraidas.some(ciudad => ciudad.Id === ciudadElegida);
                if (!ciudadPresente) {
                    setCiudadElegida(0);
                }
                setCiudadesEstadoEspecifico(ciudadesTraidas);
            }
            traerCiudadesDeEstado();
        }
    }, [estadoElegido]);

    return {
        paises,
        estadosPaisEspecifico,
        ciudadesEstadoEspecifico,
        setPaisElegido,
        setCiudadElegida,
        setEstadoElegido,
        paisElegido,
        ciudadElegida,
        estadoElegido,
    }
}

export { useLugares }