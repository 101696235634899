// servicio que trae del backend todos los turnos medicos confirmados por ambas partes para un determinado doctor en sesion
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';  // declaracion de variable de entorno de la API

const getEspecialidadesPsicoDisp = async (token) => {
    const todasEsps = await axios.get(`${apiUrl}/api/plataforma_medica/especialidades/psicoPsiqui/disps`, { headers: { Authorization: `Bearer ${token}` } });
    const especialidadesTraidas = todasEsps.data;
    return especialidadesTraidas;
};

const createConsultaPsicoAdmision = async (data, token) => {
    try {
        // console.log("data en el servicio: ", data)
        // console.log("token en el servicio: ", token)
        const response = await axios.post(`${apiUrl}/api/plataforma_medica/consultas/psicoAdmision`, data, { headers: { Authorization: `Bearer ${token}` } })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}

const psicoAdmisionServices = {
    getEspecialidadesPsicoDisp,
    createConsultaPsicoAdmision
};

export { psicoAdmisionServices }