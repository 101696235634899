import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import "./BotonCapacitaciones.css";
import useIp from '../IniciarSesion/hooks/useIp.js';
import { createEvento } from '../../../../services/evento.service.js';

// llamar a esto en un futuro: BotonContacto
const BotonCapacitaciones = ({ link, token, usuarioEnSesion }) => {
    const navigate = useNavigate();
    const ip = useIp()

    const crearE = async (data, token) => {
        await createEvento(data, token)
    }

    return (
        <a
            data-tooltip-id="my-tooltip-cap"
            data-tooltip-content="Como usar DoctorFY?"
            id="boton-capacitaciones"
            target='_blank'
            href={link}
        >
            <button className='btn waves-effect waves-light grey darken-2' id="boton-capacitaciones" onClick={() => crearE({ id_tipo: 32, id_resultado: 1, ip_user: ip, id_usuario: usuarioEnSesion?.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion?.usuarioComun ? usuarioEnSesion.Id : null }, token)}>
                <i className='medium material-icons text-darken-1' id="icono-capacitaciones">local_library</i>
            </button>
            <Tooltip
                id='my-tooltip-cap'
            />
        </a>

    );
};

export { BotonCapacitaciones }