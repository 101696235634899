import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const SessionExpired = ({ setUsuarioEnSesion, setToken, setEstaEnSesion, setEstaEnAjustes, setEstaEnPregFrec, setEstaEnContacto }) => {
    const navigate = useNavigate();

    const handleCloseSesion = () => {
        setEstaEnSesion(false)
        setUsuarioEnSesion(null)
        setToken(null)
        window.localStorage.removeItem("DoctorFy");
        navigate('/inicioSesion')
        setEstaEnAjustes(false)
        setEstaEnContacto(false)
        setEstaEnPregFrec(false)
    }

    useEffect(() => {
        setEstaEnSesion(false)
    }, [])

    return (
        <div className='col s12 center'>
            <h2>OOPS. Parece que tu sesión ha caducado. Por favor volve a iniciar sesion</h2>
            <div className='col s12 center' id='btn-close-sesion' style={{ marginTop: '24px' }}>
                <button className='btn waves-effect waves-light' type='button' onClick={() => handleCloseSesion()}>
                    CERRAR SESION
                </button>
            </div>
        </div>
    )
}

export { SessionExpired }
