import React, { createRef, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { SessionExpired } from '../../../components/SessionExpired/SessionExpired';
import logoPTM from "../../../../images/newLogoPTM.jpeg"
import { useForm } from 'react-hook-form';
import "./Pagos2.css"
import { solicTurnoProfServices } from '../../../SolicitudTurno/SolicitudTurnoMedico/services/solicTurnoProf.service';
import { Modal } from '../../../components/Modal/Modal';
import { mediosPagoServices } from '../SeleccionMedio/services/medios_pago.service';
import paywaylogo from "../../../../images/LogoPayway.png"
import useIp from '../../../PantallaInicial/components/IniciarSesion/hooks/useIp';
import { useEvento } from '../../../../hooks/useEvento';

// este Pagos2 representa el formulario para trabajar con tarjetas VISA de Credito

const Pagos2 = ({
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
    showModal,
    handleOpenModal,
    handleCloseModal
}) => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm()
    const [tokenVencido, setTokenVencido] = useState(false)
    const [errorFecha, setErrorFecha] = useState(false)
    const { idMedio, idTurno } = useParams()
    const [errorAlPagar, setErrorAlPagar] = useState(false)
    const [pulsoPagar, setPulsoPagar] = useState(false)
    const [modeloForm, setModeloForm] = useState(0)

    const ip = useIp()
    const { handleCampoLlego, campoLlego, crearE } = useEvento()

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerDatos = async () => {
        const modelo = await mediosPagoServices.getModeloForm(token, idMedio)
        if (modelo.error !== undefined) {
            if (modelo.error === "TOKEN VENCIDO.") {
                setTokenVencido(true);
                return;
            }
        }

        setModeloForm(modelo)
    }
    useEffect(() => {
        traerDatos()
    }, [])


    const onSubmit = async (data) => {
        setPulsoPagar(true)

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
        const currentYear = currentDate.getFullYear() % 100;

        const userMonth = parseInt(data.card_expiration_month);
        const userYear = parseInt(data.card_expiration_year);

        if (userYear < currentYear || (userYear === currentYear && userMonth < currentMonth)) {
            setErrorFecha(true);
            setPulsoPagar(false)
            return;
        }
        setErrorFecha(false)



        const urlSandbox = "https://live.decidir.com/api/v2"; // url de desarrollo de payway
        const publicAPIKEY = "ec9a406282094dd8914f9e1e5cd305ee"
        const decidir = new window.Decidir(urlSandbox, true); // accedo al SDK de frontend
        decidir.setTimeout(60000) // seteo tiempo maximo de rta
        decidir.setPublishableKey(publicAPIKEY) // seteo la clave publica

        const sdkResponseHandler = async (status, res) => {
            if (status !== 200 && status !== 201) {
                // console.log("Error en la creación del token: ", res);
            } else {
                data.tokenData = { ...res };
                data.IdEstadoTurno = 4 // el paciente acepto el turno ofrecido
                data.medioPago = parseInt(idMedio) // tarjeta

                const response = await solicTurnoProfServices.updateTurno(idTurno, data, token, 3)

                if (response.error === "Error al realizar el pago. Por favor, revisá los datos y el monto en tu Tarjeta.") {
                    setErrorAlPagar(true);
                    setPulsoPagar(false)
                    handleOpenModal();
                    return;
                }

                if (response.error === "TOKEN VENCIDO.") {
                    setTokenVencido(true);
                    setPulsoPagar(false)
                    return;
                }

                handleOpenModal();
                setPulsoPagar(false)
            }
        }

        const formulario = document.querySelector('#pago_form'); // obtengo el formulario
        await decidir.createToken(formulario, sdkResponseHandler); // creo el token con el formulario y la funcion de callback
    }

    const handleKeyDown = (event, idCampoLlego) => {
        if (['+', '-', 'e', '.', ','].includes(event.key)) {
            event.preventDefault();
        }
        handleCampoLlego(idCampoLlego)
    };

    const handleBack = () => {
        navigate(`/pagos11/${idTurno}`)
        if (campoLlego !== null) {
            crearE({ id_tipo: 70, id_resultado: 2, ip_user: ip, id_form: 14, id_campo_llego: campoLlego }, token)
            crearE({ id_tipo: 71, id_resultado: 2, ip_user: ip, id_form: 14, id_campo_llego: campoLlego }, token)
        } else {
            crearE({ id_tipo: 71, id_resultado: 1, ip_user: ip }, token)
        }
    }



    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="form-registro-container row">
                        <div className="row">
                            <div className='col s12 center'>
                                <div className="logo-container center">
                                    <img src={logoPTM} alt="Logo PTM" width="500" />
                                </div>
                                <h5 id="header-form-registro">PAGAR TURNO</h5>
                            </div>
                        </div>

                        <div className='row' style={{ marginBottom: "4px" }}>
                            <div className="col s6">
                                <h5>Usted va a realizar un pago seguro a través de: </h5>
                            </div>
                            <div className="col s6">
                                <img alt="LogoPayway" src={paywaylogo} />
                            </div>
                        </div>

                        <form id="pago_form" className="row" onSubmit={handleSubmit(onSubmit)}>
                            <div className="col s12">
                                <div className='row'>
                                    <div className="col s6">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="card_number">Número de Tarjeta</label>
                                        <input id="card_number" onKeyDown={(e) => handleKeyDown(e, 81)} data-decidir="card_number" type="number" placeholder="*****************" name="card_number" style={{ fontWeight: "bold", marginBottom: "0px" }} className="validate input_p2" required {...register('card_number', {
                                            required: {
                                                value: true,
                                                message: "El número de tarjeta es requerido."
                                            },
                                            pattern: {
                                                value: modeloForm === 1 ? /^\d{16}$/ : modeloForm === 2 ? /^\d{14}$/ : modeloForm === 3 ? /^\d{15}$/ : /^\d{18}$/,
                                                message: modeloForm === 1 ? "Exactamente 16 números" : modeloForm === 2 ? "Exactamente 14 números" : modeloForm === 3 ? "Exactamente 15 números" : "Exactamente 18 números"
                                            }
                                        })} />
                                        {errors.card_number && (
                                            <span className='error-message'>{errors.card_number.message}</span>
                                        )}
                                    </div>

                                    <div className="col s6">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="card_expiration_month">Mes Vto.</label>
                                        <select onChangeCapture={() => handleCampoLlego(82)} id="card_expiration_month" data-decidir="card_expiration_month" name="card_expiration_month" style={{ fontWeight: "bold", marginBottom: "1px" }} className='validate browser-default' required {...register('card_expiration_month', {
                                            required: {
                                                value: true,
                                                message: "Mes es requerido."
                                            },
                                        })} >
                                            <option value="" disabled selected style={{ fontWeight: "bold" }}>Mes</option>
                                            <option value="01" style={{ fontWeight: "bold" }}>01</option>
                                            <option value="02" style={{ fontWeight: "bold" }}>02</option>
                                            <option value="03" style={{ fontWeight: "bold" }}>03</option>
                                            <option value="04" style={{ fontWeight: "bold" }}>04</option>

                                            <option value="05" style={{ fontWeight: "bold" }}>05</option>
                                            <option value="06" style={{ fontWeight: "bold" }}>06</option>
                                            <option value="07" style={{ fontWeight: "bold" }}>07</option>
                                            <option value="08" style={{ fontWeight: "bold" }}>08</option>

                                            <option value="09" style={{ fontWeight: "bold" }}>09</option>
                                            <option value="10" style={{ fontWeight: "bold" }}>10</option>
                                            <option value="11" style={{ fontWeight: "bold" }}>11</option>
                                            <option value="12" style={{ fontWeight: "bold" }}>12</option>
                                        </select>

                                        {errors.card_expiration_month && (
                                            <span className='error-message'>{errors.card_expiration_month.message}</span>
                                        )}
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col s6">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="card_expiration_month">Año Vto.</label>

                                        <select onChangeCapture={() => handleCampoLlego(83)} id="card_expiration_year" data-decidir="card_expiration_year" name="card_expiration_year" style={{ fontWeight: "bold", marginBottom: "1px" }} className='validate browser-default' required {...register('card_expiration_year', {
                                            required: {
                                                value: true,
                                                message: "Año es requerido."
                                            },
                                        })} >
                                            <option value="" disabled selected style={{ fontWeight: "bold" }}>Año</option>
                                            <option value="24" style={{ fontWeight: "bold" }}>24</option>
                                            <option value="25" style={{ fontWeight: "bold" }}>25</option>
                                            <option value="26" style={{ fontWeight: "bold" }}>26</option>
                                            <option value="27" style={{ fontWeight: "bold" }}>27</option>
                                            <option value="28" style={{ fontWeight: "bold" }}>28</option>
                                            <option value="29" style={{ fontWeight: "bold" }}>29</option>
                                            <option value="30" style={{ fontWeight: "bold" }}>30</option>
                                            <option value="31" style={{ fontWeight: "bold" }}>31</option>
                                            <option value="32" style={{ fontWeight: "bold" }}>32</option>
                                            <option value="33" style={{ fontWeight: "bold" }}>33</option>
                                            <option value="34" style={{ fontWeight: "bold" }}>34</option>
                                        </select>
                                        {errors.card_expiration_year && (
                                            <span className='error-message'>{errors.card_expiration_year.message}</span>
                                        )}
                                    </div>

                                    <div className="col s6">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="fecha_vto">Código de seguridad</label>
                                        <input id="security_code" data-decidir="security_code" onKeyDown={(e) => handleKeyDown(e, 84)} type="number" name="security_code" placeholder={modeloForm !== 3 ? "***" : "****"} style={{ fontWeight: "bold", marginBottom: "0px" }} className="validate input_p2" required {...register('security_code', {
                                            required: {
                                                value: true,
                                                message: "Código de seguridad es requerido."
                                            },
                                            pattern: {
                                                value: modeloForm !== 3 ? /^\d{3}$/ : /^\d{4}$/,
                                                message: modeloForm !== 3 ? "Sólo 3 números." : "Sólo 4 números."
                                            }
                                        })} />
                                        {errors.security_code && (
                                            <span className='error-message'>{errors.security_code.message}</span>
                                        )}
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className="col s6">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="fecha_vto">Titular de Tarjeta</label>
                                        <input onKeyDown={() => handleCampoLlego(85)} id="card_holder_name" data-decidir="card_holder_name" type="text" name="card_holder_name" placeholder="ej: José Pérez" style={{ fontWeight: "bold", marginBottom: "0px" }} className="validate input_p2" required {...register('card_holder_name', {
                                            required: {
                                                value: true,
                                                message: "Titular de tarjeta es requerido."
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: "Menos de 50 caracteres"
                                            }
                                        })} />
                                        {errors.card_holder_name && (
                                            <span className='error-message'>{errors.card_holder_name.message}</span>
                                        )}
                                    </div>
                                    <div className="col s6">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="card_holder_doc_number">DNI</label>
                                        <input id="card_holder_doc_number" data-decidir="card_holder_doc_number" type="number" onKeyDown={(e) => handleKeyDown(e, 86)} name="card_holder_doc_number" placeholder="ej: 12345678" style={{ fontWeight: "bold", marginBottom: "0px" }} className="validate input_p2" required {...register('card_holder_doc_number', {
                                            required: {
                                                value: true,
                                                message: "Titular de tarjeta es requerido."
                                            },
                                            pattern: {
                                                value: /^\d{7,8}$/,
                                                message: "Exactamente 7 u 8 números"
                                            }
                                        })} />
                                        {errors.card_holder_doc_number && (
                                            <span className='error-message'>{errors.card_holder_doc_number.message}</span>
                                        )}
                                    </div>
                                </div>

                                {errorFecha && (
                                    <div className='col s12 center'>
                                        <span className='error-message'>Error. Ingresá un mes y un año válidos</span>
                                    </div>
                                )}
                            </div>


                            <div className="col s12 center" style={{ marginBottom: "80px", marginTop: "20px" }}>
                                <button className='btn waves-effect waves-light' type="submit" style={{ margin: "8px" }} disabled={pulsoPagar}>
                                    PAGAR
                                    <i className='material-icons right large'>account_box</i>
                                </button>

                                <button
                                    className='btn waves-effect waves-light'
                                    type="button"
                                    onClick={handleBack}
                                    style={{ margin: "8px" }}
                                >
                                    VOLVER
                                </button>
                            </div>
                        </form>
                    </div>

                    {showModal && (
                        <Modal
                            title={errorAlPagar === true ? 'ERROR AL PAGAR' : 'PAGO REALIZADO CON ÉXITO'}
                            description={errorAlPagar === true ? "Error al realizar el pago. Por favor, revisá los datos y el monto en tu Tarjeta." : "Realizaste el pago de tu consulta con éxito. Revisá tu correo electrónico para ver el comprobante de pago."}
                            onClose={errorAlPagar === true ? () => { handleCloseModal(); crearE({ id_tipo: 74, id_resultado: 2, ip_user: ip }, token); setErrorAlPagar(false) } : () => { handleCloseModal(); crearE({ id_tipo: 73, id_resultado: 2, ip_user: ip }, token); navigate('/misOfertasTurnoPac'); setErrorAlPagar(false) }}
                        />
                    )}
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    )
}

export { Pagos2 }
