// servicio que trae del backend todos los turnos medicos confirmados por ambas partes para un determinado doctor en sesion
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';  // declaracion de variable de entorno de la API

const getTurnosConfirmadosForPaciente = async (idPac, token) => {
    try {
        const todosTurnos = await axios.get(`${apiUrl}/api/plataforma_medica/turnos/turnosConfirmadosByPaciente/`, { headers: { Authorization: `Bearer ${token}` } });
        return todosTurnos.data
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
};

const turnosConfrimadosForPacienteServices = {
    getTurnosConfirmadosForPaciente
};

export { turnosConfrimadosForPacienteServices }