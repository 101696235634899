import React, { } from 'react'
import { useNavigate } from 'react-router-dom'
import logoPTM from "../../../images/newLogoPTM.jpeg"
import "./PreguntasFrecuentesProf.css"
import useIp from '../../PantallaInicial/components/IniciarSesion/hooks/useIp.js'
import { createEvento } from '../../../services/evento.service.js'
import { useEvento } from '../../../hooks/useEvento.js'

const PreguntasFrecuentesProf = ({ setEstaEnPregFrec, token, usuarioEnSesion }) => {

    const navigate = useNavigate()

    const ip = useIp()
    const { handleCampoLlego, campoLlego, crearE } = useEvento()

    const handleBack = () => {
        setEstaEnPregFrec(false)
        navigate(-1)
        crearE({ id_tipo: 23, id_resultado: 1, ip_user: ip, id_usuario: usuarioEnSesion?.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion?.usuarioComun ? usuarioEnSesion.Id : null }, token)
    }

    return (
        <>
            <div className="row">
                <div className="col s12 m6 offset-m3">
                    <div className="logo-container center">
                        <img src={logoPTM} alt="Logo PTM" width="500" />
                    </div>
                    <h4 className="center-align" style={{ color: 'blue' }}>PREGUNTAS FRECUENTES</h4>
                    {/* <h5 className="center-align" style={{ color: 'blue' }}>Sección Profesionales</h5> */}
                </div>
            </div>


            <ol className='lista-preg-frec-prof'>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Los pacientes me contactan directamente desde la pag con los datos que yo
                        proporciono (mail, teléfono, dirección)?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> No. Los pacientes, previo a la toma de conocimiento personal, ya sea en
                        modalidad presencial o virtual, no tienen la posibilidad de tomar contacto directo con el
                        profesional, toda vez que de ningún modo la plataforma proporciona a los usuarios, ni a otros
                        profesionales ni a ninguna otra persona datos sensibles como teléfono (fijo o celular), email,
                        domicilio particular o profesional, etc., en cumplimiento de la ley 25.326 de la República
                        Argentina; único ámbito de actividad de la Plataforma. La única manera en que los usuarios de
                        la misma obtengan datos de contacto con el Profesional, es que estos les sean proporcionados
                        por el propio profesional, por ejemplo, en una instancia de consulta, o bien por terceros
                        (Cartillas de Prepagas, sitios web de difusión de información sobre profesionales de la salud,
                        etc.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Cómo garantizan que no me lleguen al consultorio directamente a pedir un
                        turno?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> Del modo expuesto en la respuesta a la pregunta 1.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Hay una instancia de derivación entre los pacientes y mis datos de ubicación?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> No hay tal instancia entre las funcionalidades de la Plataforma. Se reitera que en
                        modo alguno la Plataforma proporciona datos de contacto de los profesionales ni a los
                        usuarios generales (pacientes) ni a ninguna persona o entidad, toda vez que ello implicaría una
                        infracción a la Ley de Protección de Datos 25.326. Lo anterior no implica que tales datos, una
                        vez proporcionados por el profesional a sus pacientes captados a través de nuestra
                        Plataforma, no puedan ser difundidos por los pacientes.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿El paciente elige libremente la especialidad psi? y si no sabe cuál es más
                        conveniente a su patología?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> Si. Ese es el modo de funcionamiento de la Plataforma. Debe aclararse aquí que
                        Doctorfy no realiza en modo alguno práctica profesional de ninguna naturaleza, limitándose
                        estrictamente a la gestión de turnos. La responsabilidad profesional está en cabeza de los
                        profesionales que ofrecen y brindan sus servicios a través de la Plataforma. A los fines de
                        evacuar la duda planteada, puede sugerirse tomar la primera consulta como consulta de
                        admisión y en ella comunicar al paciente la opinión profesional respecto de la especialidad
                        elegida y asesorarlo al respecto.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> Las consultas pueden ser presenciales y virtuales?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> Si. En la instancia de solicitud de turno el usuario debe optar por una de las dos
                        modalidades.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Para las consultas virtuales tienen plataforma propia? o uso mis propios
                        dispositivos? zoom, meet, whatsapp, etc.
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> La Plataforma no ofrece herramientas de Steaming. El profesional debe optar por
                        alguna de ellas, y enviar al paciente el link, o bien llamarlo por video llamada de whatsapp en
                        el día y hora establecidas para la consulta. Se aclara que los profesionales si reciben los datos
                        de contacto de los pacientes, toda vez que estos consienten en que sus datos personales sean
                        proporcionados al profesional una vez aceptada la oferta de turno.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Cuánto cobran por registrarse?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> Tanto la registración como la permanencia en la plataforma son gratuitas tanto
                        para los profesionales como para los usuarios. La plataforma monetiza por el uso de la misma.
                        Para mayores detalles del modelo de negocio, ver la respuesta a la pregunta siguiente.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Cómo me pagan la consulta los pacientes?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> Los pacientes pagan, tanto los honorarios profesionales como el cargo por el uso
                        de la plataforma mediante una pasarela de pago a la cual accede en la instancia de aceptar una
                        oferta de Turno. La pasarela de Pago le cobra, por el medio de pago elegido por el usuario, los
                        dos conceptos mencionados. El profesional recibe la notificación de aceptación del turno por
                        parte del paciente SOLO una vez abonados por este los honorarios del profesional y el fee de
                        uso de la Plataforma. El importe abonado por el paciente es percibido por Doctorfy. Al cierre
                        de cada período mensual, el primer día hábil del mes siguiente al de la prestación, cada
                        Profesional recibe una orden de facturación. La factura debe emitirse contra EngTrade
                        Servicios de Consultoría, empresa propietaria de Doctorfy, y remitirse por email. Recibida la
                        factura, la misma será cancelada mediante transferencia bancaria dentro de las 48 horas de
                        recibida.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Cuál es el costo como profesional de pertenecer a la plataforma?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> Ninguno. El costo por uso de la Plataforma es abonado por el paciente.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿En el caso de los trt largos o continuados como las psicoterapias, por cada
                        sesión cobra la plataforma? o solo el primer contacto y luego continúa la relación directa con
                        el paciente?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> El modelo de negocio de Doctorfy contempla que toda la gestión de turnos sea
                        realizada a través de nuestra Plataforma. Sin embargo, conocemos y asumimos la
                        imposibilidad fáctica de controlar este flujo. Como consecuencia de lo anterior, entendemos
                        que debe implementarse una suerte de “Honor System”, conforme al cual sea el profesional
                        quien opte por honrar su compromiso con nosotros.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Qué seguridad tengo con la entrega de mis datos confidenciales, como foto
                        de matrícula o título?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> La Plataforma no solicita tales documentos en ningún caso. Ver para mayor
                        detalle la respuesta a la pregunta 1 sobre el tratamiento de datos sensibles.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿La cobertura es nacional?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> Si.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Cuándo se lanza al mercado la plataforma?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> En cuanto se alcance el número de profesionales y especialidades que se
                        consideran mínimos para atender al primer paciente registrado, cuyo registro disparará el
                        turnkey de la Plataforma. Se estima que dicho evento se producirá durante el próximo marzo.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Cómo llegan los pacientes a la plataforma?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> Mediante acciones de Márketing directo y por redes sociales.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Que soporte tengo?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> Tanto los Profesionales como los usuarios generales cuentan con soporte técnico
                        24/7, vía email.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Existe algún asesoramiento legal?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> La Empresa cuenta con asesoramiento legal, pero no se prevé que este sea
                        extensivo a los profesionales registrados en la Plataforma.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Existe la red de derivación si yo no puedo o no corresponde a mi especialidad
                        un paciente que tomo por primera vez?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> Ver respuesta a la Pregunta 4.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Cómo es la facturación a la plataforma?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> Ver respuesta a Pregunta 8.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Qué garantía tengo que me van a pagar la consulta, hay que firmar un
                        contrato con la plataforma?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> La finalización del Proceso de Registración, mediante la entrevista de verificación
                        con nuestra Dirección Médica implica un contrato firmado vía email, mediante la aceptación
                        expresa de los términos y condiciones de uso de la Plataforma. No existe, en los hechos, más
                        garantía de pago que el conocimiento recíproco entre el profesional y la persona que lo invita
                        a registrarse en la Plataforma; y los antecedentes de las personas que integran EngTrade SC.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> Si se contactan pacientes del exterior hay cobertura?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> El ámbito de actividad de Doctorfy se limita a la República Argentina. Sin
                        embargo, no podemos garantizar que pacientes del exterior se contacten. La opción de
                        atenderlos de manera virtual es de responsabilidad exclusiva de los Profesionales, teniendo
                        especialmente en cuenta las leyes, tanto de la República Argentina como del país de residencia
                        del paciente, que reglamenten el ejercicio de la profesión correspondiente, así como el alcance
                        de su cobertura de mala praxis. En el caso de consultas presenciales, no vemos dificultad para
                        el profesional en su atención.
                    </p>
                </li>
                <li className='item-lista-preg-frec-prof'>
                    <p>
                        <strong style={{ fontWeight: "700" }}>PREGUNTA:</strong> ¿Es como una cartilla de prepaga, pero con pago por consulta?
                    </p>
                    <p>
                        <strong style={{ fontWeight: "700" }}>RESPUESTA:</strong> No. No lo es. Es una Plataforma de Gestión de Turnos que garantiza su obtención
                        dentro de las 72 horas de solicitado.
                    </p>
                </li>
            </ol>

            <div className='col s12 center'>
                <button
                    className='btn waves-effect waves-light'
                    type="button"
                    onClick={handleBack}
                    style={{ marginTop: "20px", marginBottom: "100px" }}
                >
                    VOLVER
                </button>
            </div>
        </>

    )
}


export { PreguntasFrecuentesProf }