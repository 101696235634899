import React from "react";
import { Link } from "react-router-dom";
import './ErrorNotFound.css';

// pagina de 404 en caso de que el usuario ingrese un link/ruta inexistente
const ErrorNotFound = () => {
    return (
        <section>
            <div className="error-404-container">
                <div className="row justify-content-center">
                    <div className="error-404-content">
                        <h3 id="error-404-header">ERROR 404: NOT FOUND</h3>
                        <h5 id="error-404-subheader">
                            NO SE ENCONTRÓ LA PÁGINA, POR FAVOR INTENTA DE NUEVO
                        </h5>
                        <p className="error-404-text">
                            El link al que acabas de entrar no existe o no está disponible en este momento,
                            por favor, anda a la página de inicio.
                            <br />
                            <br />
                            ENGTRADE ©2020-2023
                        </p>
                        <div className="col s12 center">
                            <Link to='/'>
                                <button type="button" className="btn waves-effect waves-light">
                                    <i className="material-icons left">home</i>
                                    IR A INICIO
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export { ErrorNotFound };