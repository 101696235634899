import React from 'react';
import "./BotonPantallaPrincipal.css";

// creando un componente el cual representa un boton de la pantalla principal y mediante render props, tendra una
// funcionalidad u otra, tambien las render props afectan en los estilos de dicho boton
const BotonPantallaPrincipal = ({ onClick, icon, tooltip, color }) => {
    const buttonStyle = {
        backgroundColor: color, // Agrega el color de fondo deseado al boton
    };

    // renderizado de un boton de la pantalla principal
    return (
        <>
            <span className='tooltip'>
                <a
                    className={`btn-floating waves-effect waves-light`}
                    data-tip={tooltip}
                    onClick={onClick}
                    style={buttonStyle}
                >
                    <i className="material-icons">{icon}</i>
                </a>
                {tooltip && <span className="tooltiptext">{tooltip}</span>}
            </span>
        </>
    );
};

// exportando el boton de la pantalla principal
export { BotonPantallaPrincipal }