import React from 'react'
import { Tooltip } from 'react-tooltip';
import "../PantallaInicial/components/BotonConfiguracionCuenta/BotonConfiguracionCuenta.css";
import "../PantallaInicial/components/BotonPreguntasFrec/BotonPreguntasFrec.css";
import "../PantallaInicial/components/BotonNotificaciones/BotonNotificaciones.css"
import { useNavigate } from "react-router-dom"

export default function Buttons({ setEstaEnContacto }) {
    const navigate = useNavigate()
    return (
        <>
            <a
                data-tooltip-id="my-tooltip-config"
                data-tooltip-content="Preguntas Frecuentes Usuarios"
                id="boton-config-cta"
            >
                <button className='btn waves-effect waves-light grey darken-2' id="boton-config-cta" onClick={() => { navigate("/preguntasFrecuentesPac") }}>
                    <i className='medium material-icons' id="icono-config-cta">help</i>
                </button>
                <Tooltip
                    id='my-tooltip-config'
                />
            </a>
            <a
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Preguntas Frecuentes Profesionales"
                id="boton-preg-frec"
            >
                <button
                    className='btn waves-effect waves-light blue darken-2'
                    id="boton-preg-frec"
                    onClick={() => { navigate("/preguntasFrecuentesProf") }}

                ><i className='medium material-icons' id="icono-preg-frec">help</i>
                </button>
                <Tooltip
                    id='my-tooltip'
                    style={{ zIndex: "9998" }}
                />
            </a>
            <a
                data-tooltip-id="my-tooltip-contacto"
                data-tooltip-content="Contacto"
                id="boton-notificaciones-cta"
            >
                <button onClick={() => { setEstaEnContacto(true); navigate("/contacto") }} className='btn waves-effect waves-light grey darken-2' id="boton-notificaciones-cta">
                    <i className='medium material-icons text-darken-1' id="icono-notificaciones-cta">email</i>
                </button>
                <Tooltip
                    id='my-tooltip-contacto'
                />
            </a>

        </>
    )
}
