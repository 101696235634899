import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

const getMedicosParaTurno = async (data, token) => {
    try {
        let response
        if (data.esPsico) {
            if (data.esPsico === true) {
                response = await axios.get(`${apiUrl}/api/plataforma_medica/consultas/mailsMedPsico/${data.especialidad}/${data.idPaciente}`, { headers: { Authorization: `Bearer ${token}` } })
            }
        } else {
            response = await axios.get(`${apiUrl}/api/plataforma_medica/turnos/getMedsCity/${data.ciudad}/${data.especialidad}`, { headers: { Authorization: `Bearer ${token}` } });
        }
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}

const createTurnoParaOtro = async (data, token) => {
    try {
        const response = await axios.post(`${apiUrl}/api/plataforma_medica/turnos/`, data, { headers: { Authorization: `Bearer ${token}` } })
        return response.data
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}

const solicTurnoParaOtroServices = {
    createTurnoParaOtro,
    getMedicosParaTurno
}

export { solicTurnoParaOtroServices }