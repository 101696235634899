import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

const getPrecioDrFy = async (token) => {
    try {
        const precio = await axios.get(`${apiUrl}/api/plataforma_medica/bo/drfy_precios/1`, { headers: { Authorization: `Bearer ${token}` } });
        return precio.data
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}

const pagos1Services = {
    getPrecioDrFy
}

export { pagos1Services }