import { useEffect, useState } from "react";
import { createEvento } from "../services/evento.service";

const useEvento = () => {
    const [campoLlego, setCampoLlego] = useState(null)

    const handleCampoLlego = (campo) => {
        if (campo >= campoLlego)
            setCampoLlego(campo);
    }

    const crearE = async (data, token) => {
        await createEvento(data, token)
    }

    return {
        campoLlego,
        handleCampoLlego,
        crearE
    }
}

export { useEvento };