import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

const getMediosPago = async (token) => {
    const mediosPagoTraidos = await axios.get(`${apiUrl}/api/plataforma_medica/bo/mediosPago`, { headers: { Authorization: `Bearer ${token}` } });
    const mediosPago = mediosPagoTraidos.data;
    return mediosPago
}

const getModeloForm = async (token, idMedioPago) => {
    const modeloForm = await axios.get(`${apiUrl}/api/plataforma_medica/bo/mediosPago/modeloForm/${idMedioPago}`, { headers: { Authorization: `Bearer ${token}` } });
    return modeloForm.data
}

const getIdPayway = async (token, idMedioPago) => {
    const modeloForm = await axios.get(`${apiUrl}/api/plataforma_medica/bo/mediosPago/idPayway/${idMedioPago}`, { headers: { Authorization: `Bearer ${token}` } });
    return modeloForm.data
}

const mediosPagoServices = {
    getMediosPago,
    getModeloForm,
    getIdPayway
}

export { mediosPagoServices }