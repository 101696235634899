import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import logoPTM from "../../../images/newLogoPTM.jpeg"
import axios from 'axios'
import './TomaConsulta.css'
import { Tooltip } from "react-tooltip"
import { Modal } from '../../components/Modal/Modal.js'
import { solicTurnoProfServices } from '../../SolicitudTurno/SolicitudTurnoMedico/services/solicTurnoProf.service.js'
import { SessionExpired } from '../../components/SessionExpired/SessionExpired.js'
import useIp from '../../PantallaInicial/components/IniciarSesion/hooks/useIp.js'
import { useEvento } from '../../../hooks/useEvento.js'

const TomaConsulta = ({
    apiUrl,
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
    showModal,
    handleOpenModal,
    handleCloseModal
}) => {
    const navigate = useNavigate()
    const { control, reset, register, setValue, handleSubmit, formState: { errors } } = useForm()

    const [turnoConsultado, setTurnoConsultado] = useState({})
    const { id } = useParams();
    const [hizoClick, setHizoClick] = useState(false)

    // GESTION FECHA
    // Obtener la fecha actual
    const fechaActual = new Date();

    // Obtener el día, mes y año
    const dia = fechaActual.getDate();
    const mes = fechaActual.getMonth() + 1; // Ten en cuenta que los meses en JavaScript van de 0 a 11
    const año = fechaActual.getFullYear();

    // Formatear la fecha
    const fechaFormateada = `${dia < 10 ? '0' + dia : dia}-${mes < 10 ? '0' + mes : mes}-${año}`;

    const [tokenVencido, setTokenVencido] = useState(false)

    const ip = useIp()
    const { handleCampoLlego, campoLlego, crearE } = useEvento()

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
            setHizoClick(false)
        }
    }, []);

    const traerElTurno = async () => {
        if (window.location.pathname.includes('tomaConsulta')) {
            if (usuarioEnSesion) {
                const resultado = await solicTurnoProfServices.getTurnoById(id, token, 6)
                if (resultado.error !== undefined) {
                    if (resultado.error === "TOKEN VENCIDO.") {
                        setTokenVencido(true);
                        return;
                    }
                    if (resultado.esCero) {
                        navigate("*")
                    }
                }
                setTurnoConsultado(resultado)
            }
        }
    }

    // traigo el turno a consultar
    useEffect(() => {
        traerElTurno()
    }, [usuarioEnSesion]);

    const handleBack = () => {
        navigate("/")
        setHizoClick(false)
    }

    const onSubmit = async (data) => {
        setHizoClick(true)
        data.idTurno = parseInt(id)

        // la vuelvo a parsear porque SQL en el backend admite dateonly solo de la forma yyyy-mm-dd
        const [dia, mes, año] = fechaFormateada.split('-');
        data.fecha = `${año}-${mes}-${dia}`
        data.IdEstadoTurno = 9

        // await axios.put(`${apiUrl}/api/plataforma_medica/turnos/actualizar/${data.idTurno}`, data)
        const response = await solicTurnoProfServices.updateTurno(data.idTurno, data, token, 7)
        if (response.error === "TOKEN VENCIDO.") {
            setTokenVencido(true);
            setHizoClick(false)
            return;
        }

        navigate("/bienvenida")
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className='row'>
                        <div className="logo-container center">
                            <img src={logoPTM} alt="Logo PTM" width="500" />
                        </div>

                        <div className='col s12 center'>
                            <h5 id="header-form-registro" className='center-align'>TOMA DE CONSULTA</h5>
                        </div>

                        {/* <div className='col s12 center'>
                    <button
                        className='btn waves-effect waves-light'
                        type="button"
                        onClick={handleBack}
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                        VOLVER
                    </button>
                </div> */}

                        {turnoConsultado?.Motivo === undefined ? (
                            <p>CARGANDO...</p>
                        ) : (
                            <>
                                <div className='col s12' id='lastTextTC'>
                                    {turnoConsultado?.IdProfesional !== null && (
                                        <span style={{ fontWeight: "500", display: "block" }}>Dr/Dra: {turnoConsultado?.Profesionale?.Nombre?.toUpperCase()} {turnoConsultado?.Profesionale?.Apellido?.toUpperCase()}</span>
                                    )}

                                    {fechaFormateada && (
                                        <span style={{ fontWeight: "500", display: "block" }}>Fecha: {fechaFormateada}</span>
                                    )}

                                    <br></br>

                                    <span style={{ fontWeight: "500", display: "block" }}>DATOS DEL PACIENTE: </span>
                                    {turnoConsultado.IdPacAAtender !== null ? (
                                        <>
                                            <span style={{ fontWeight: "500", display: "block" }}>NOMBRE Y APELLIDO: {turnoConsultado?.PacientesNoUsuario?.Nombre?.toUpperCase()} {turnoConsultado?.PacientesNoUsuario?.Apellido?.toUpperCase()}</span>
                                            <span style={{ fontWeight: "500", display: "block" }}>DATOS DE COBERTURA DEL PACIENTE: </span>
                                            <ul>
                                                {turnoConsultado?.PacientesNoUsuario?.NumeroAfiliado !== null ? (
                                                    <>
                                                        <li><span style={{ fontWeight: "500", display: "block" }}>Numero de afiliado: {turnoConsultado?.PacientesNoUsuario?.NumeroAfiliado}</span></li>
                                                        {turnoConsultado?.PacientesNoUsuario?.Obras_Sociale !== null && (
                                                            <li>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Obra social: {turnoConsultado?.PacientesNoUsuario?.Obras_Sociale?.Nombre}</span>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Numero de plan de la obra social: {turnoConsultado?.PacientesNoUsuario?.NumerosPlane?.Nombre}</span>
                                                            </li>
                                                        )}
                                                        {turnoConsultado?.PacientesNoUsuario?.Empresas_Prepaga !== null && (
                                                            <li>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Empresa de medicina prepaga: {turnoConsultado?.PacientesNoUsuario?.Empresas_Prepaga?.Nombre}</span>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Numero de plan de la empresa de medicina prepaga: {turnoConsultado?.PacientesNoUsuario?.NumerosPlane?.Nombre}</span>
                                                            </li>
                                                        )}
                                                    </>
                                                ) : (
                                                    <li><span style={{ fontWeight: "500", display: "block" }}>El paciente no tiene ni obra social ni empresa de medicina prepaga</span></li>
                                                )}
                                            </ul>
                                        </>
                                    ) : (
                                        <>
                                            <span style={{ fontWeight: "500", display: "block" }}>NOMBRE Y APELLIDO: {turnoConsultado?.Usuario?.Nombre?.toUpperCase()} {turnoConsultado?.Usuario?.Apellido?.toUpperCase()}</span>
                                            <span style={{ fontWeight: "500", display: "block" }}>DATOS DE COBERTURA DEL PACIENTE: </span>
                                            <ul>
                                                {turnoConsultado?.Usuario?.NumeroAfiliado !== null ? (
                                                    <>
                                                        <li><span style={{ fontWeight: "500", display: "block" }}>Numero de afilidado: {turnoConsultado?.Usuario?.NumeroAfiliado}</span></li>
                                                        {turnoConsultado?.Usuario?.Obras_Sociale !== null && (
                                                            <li>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Obra social: {turnoConsultado?.Usuario?.Obras_Sociale?.Nombre}</span>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Numero de plan de la obra social: {turnoConsultado?.Usuario?.NumerosPlane?.Nombre}</span>
                                                            </li>
                                                        )}
                                                        {turnoConsultado?.Usuario?.Empresas_Prepaga !== null && (
                                                            <li>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Empresa de medicina prepaga: {turnoConsultado?.Usuario?.Empresas_Prepaga?.Nombre}</span>
                                                                <span style={{ fontWeight: "500", display: "block" }}>Numero de plan de la empresa de medicina prepaga: {turnoConsultado?.Usuario?.NumerosPlane?.Nombre}</span>
                                                            </li>
                                                        )}
                                                    </>
                                                ) : (
                                                    <li><span style={{ fontWeight: "500", display: "block" }}>El paciente no tiene ni obra social ni empresa de medicina prepaga</span></li>
                                                )}
                                            </ul>
                                        </>
                                    )}

                                    <br></br>

                                    {turnoConsultado?.PacientesNoUsuario !== null && turnoConsultado?.Usuario !== null && (
                                        <>
                                            <span style={{ fontWeight: "500", display: "block" }}>DATOS DEL USUARIO SOLICITANTE DEL TURNO: </span>
                                            <span style={{ fontWeight: "500", display: "block" }}>NOMBRE Y APELLIDO: {turnoConsultado?.Usuario?.Nombre?.toUpperCase()} {turnoConsultado?.Usuario?.Apellido?.toUpperCase()}</span>
                                            <br></br>
                                        </>
                                    )}

                                    {turnoConsultado?.Motivo !== null && (
                                        <span style={{ fontWeight: "500", display: "block" }}>Motivo de la consulta: {turnoConsultado?.Motivo}</span>
                                    )}
                                </div>

                                <div className='col s12'>
                                    <label htmlFor="diagnostico" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Diagnóstico: </label>
                                    <textarea
                                        type="text"
                                        id="diagnostico"
                                        name="diagnostico"
                                        onKeyDown={() => handleCampoLlego(65)}
                                        className='validate'
                                        style={{ fontWeight: "bold" }}
                                        {...register
                                            ('diagnostico',
                                                {
                                                    required: {
                                                        value: true,
                                                        message: "El diagnóstico es requerido."
                                                    },
                                                    minLength: {
                                                        value: 10,
                                                        message: "El diagnóstico debe tener una longitud mínima de 10 caracteres"
                                                    },
                                                }
                                            )
                                        }
                                    />
                                </div>
                                {errors.diagnostico && <span className='error-message'>{errors.diagnostico.message}</span>}

                                <div className='col s12'>
                                    <label htmlFor="practicas" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Prácticas Solicitadas: </label>
                                    <textarea
                                        type="text"
                                        id="practicas"
                                        onKeyDown={() => handleCampoLlego(66)}
                                        name="practicas"
                                        className='validate'
                                        style={{ fontWeight: "bold" }}
                                        {...register
                                            ('practicas',
                                                {
                                                    minLength: {
                                                        value: 10,
                                                        message: "Este campo debe tener una longitud mínima de 10 caracteres"
                                                    },
                                                }
                                            )
                                        }
                                    />
                                </div>
                                {errors.practicas && <span className='error-message'>{errors.practicas.message}</span>}

                                <div className='col s12'>
                                    <label htmlFor="med" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Medicamentos Prescriptos: </label>
                                    <textarea
                                        type="text"
                                        id="med"
                                        name="med"
                                        onKeyDown={() => handleCampoLlego(67)}
                                        style={{ fontWeight: "bold" }}
                                        className='validate'
                                        {...register
                                            ('med',
                                                {
                                                    minLength: {
                                                        value: 5,
                                                        message: "Este campo debe tener una longitud mínima de 5 caracteres"
                                                    },
                                                }
                                            )
                                        }
                                    />
                                </div>
                                {errors.med && <span className='error-message'>{errors.med.message}</span>}

                                <div className='col s12'>
                                    <label htmlFor="indicaciones" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Indicaciones: </label>
                                    <textarea
                                        type="text"
                                        id="indicaciones"
                                        onKeyDown={() => handleCampoLlego(68)}
                                        style={{ fontWeight: "bold" }}
                                        name="indicaciones"
                                        className='validate'
                                        {...register
                                            ('indicaciones',
                                                {
                                                    minLength: {
                                                        value: 5,
                                                        message: "Este campo debe tener una longitud mínima de 5 caracteres"
                                                    },
                                                }
                                            )
                                        }
                                    />
                                </div>
                                {errors.indicaciones && <span className='error-message'>{errors.indicaciones.message}</span>}

                                <div className="col s12 center">
                                    <button
                                        className='btn waves-effect waves-light green darken-3'
                                        id="msj"
                                        type="button"
                                        onClick={() => { crearE({ id_tipo: 111, id_resultado: 1, ip_user: ip, id_usuario: usuarioEnSesion?.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion?.usuarioComun ? usuarioEnSesion.Id : null }, token); handleOpenModal() }}
                                        style={{ margin: "20px 4px 100px" }}
                                        data-tooltip-id="tooltip-RP"
                                        data-tooltip-content="RECETA ELECTRONICA"
                                    >
                                        <i className='material-icons large'>content_paste</i>
                                    </button>
                                    <Tooltip
                                        id='tooltip-RP'
                                        style={{ zIndex: 1 }}
                                    />
                                    <button
                                        className='btn waves-effect waves-light green darken-3'
                                        id="msj"
                                        type="submit"
                                        onClick={handleSubmit(onSubmit)}
                                        style={{ margin: "20px 4px 100px" }}
                                        data-tooltip-id="tooltip-FC"
                                        data-tooltip-content="FINALIZAR CONSULTA"
                                        disabled={hizoClick}
                                    >
                                        <i className='material-icons large'>done</i>
                                    </button>
                                    <Tooltip
                                        id='tooltip-FC'
                                        style={{ zIndex: 1 }}
                                    />
                                </div>

                                <div className="col s12 center">

                                </div>
                            </>
                        )}
                    </div>

                    {showModal && (
                        <Modal
                            title={'RECETA ELECTRÓNICA'}
                            description={'Estimado/a Doctor/a: Para emitir su receta, por favor, inicie sessión en el aplicativo correpondiente'}
                            onClose={() => { crearE({ id_tipo: 112, id_resultado: 1, ip_user: ip, id_usuario: usuarioEnSesion?.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion?.usuarioComun ? usuarioEnSesion.Id : null }, token); handleCloseModal() }}
                        />
                    )}
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    )
}


export { TomaConsulta }