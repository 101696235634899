import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import "./GestionNotif.css";

const GestionNotif = ({ usuarioEnSesion, setEstaEnGestionNotif }) => {
    const navigate = useNavigate();

    return (
        <a
            data-tooltip-id="my-tooltip-notif"
            data-tooltip-content="Gestionar Notificaciones"
            id="boton-notif-cta"
        >
            <button className='btn waves-effect waves-light blue darken-2' id="boton-notif-cta" onClick={() => { setEstaEnGestionNotif(true); navigate("/gestionarNotif") }}>
                <i className='medium material-icons' id="icono-notif-cta">{usuarioEnSesion.NotifActivadas === 1 ? "notifications_active" : "notifications_off"}</i>
            </button>
            <Tooltip
                id='my-tooltip-notif'
                style={{ zIndex: 9999 }}
            />
        </a>
    );
}

export { GestionNotif }