// servicio que trae los lugares de atencion del profesional que se encuentre en sesion a la hora de asignar el turno
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';  // declaracion de variable de entorno de la API

const getLugaresAtencionByProf = async (token) => {
    const lugaresAtencionTraidos = await axios.get(`${apiUrl}/api/plataforma_medica/prof_LAs/byMedico`, { headers: { Authorization: `Bearer ${token}` } });
    const lugaresAtencion = lugaresAtencionTraidos.data;
    return lugaresAtencion
}

const lugaresAtencionServices = {
    getLugaresAtencionByProf
}

export { lugaresAtencionServices }