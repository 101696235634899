// servicio que trae del backend todas las empresas prepaga existentes en la base de datos
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000'; // declaracion de variable de entorno de la API

const getAll = async () => {
    const empresas = await axios.get(`${apiUrl}/api/plataforma_medica/empresasPrepaga`);
    const empresasPrepaga = empresas.data;
    return empresasPrepaga;
};

const empresasPrepagaServices = {
    getAll
};

export { empresasPrepagaServices }