// traer aquellos turnos cuya consulta fue efectivamente atendida en el dia y hora del turno
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';  // declaracion de variable de entorno de la API

const getConsultasTomadasForProfesional = async (idProf, token) => {
    try {
        const todosTurnos = await axios.get(`${apiUrl}/api/plataforma_medica/turnos/consultasEfectivamenteTomadas/`, { headers: { Authorization: `Bearer ${token}` } });
        return todosTurnos.data
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
};

const consultasTomadasForProfesionalServices = {
    getConsultasTomadasForProfesional
};

export { consultasTomadasForProfesionalServices }