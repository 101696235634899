import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import logoPTM from '../../images/newLogoPTM.jpeg';
import { SessionExpired } from '../../modules/components/SessionExpired/SessionExpired.js';
import { MenuButton } from '../components/MenuButton.js';

const BienvenidaBO = ({
    apiUrl,
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken
}) => {
    const navigate = useNavigate();
    const [tokenVencido, setTokenVencido] = useState(false)
    const [screen, setScreen] = useState(1)

    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const handleCloseSesion = async () => {
        navigate('/loginBO')
        setEstaEnSesion(false)
        setUsuarioEnSesion(null)
        setToken(null)
        window.localStorage.removeItem("DoctorFy");
    }


    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="row">
                        <div className="col s12 m6 offset-m3">
                            <div className="logo-container center">
                                <img src={logoPTM} alt="Logo PTM" width="200" />
                            </div>
                            <h4 className="center-align" style={{ color: 'blue' }}>Panel de control de</h4>
                            <h5 className="center-align">{usuarioEnSesion?.user?.Login_nombre?.toUpperCase()} {usuarioEnSesion?.user?.Login_apellido?.toUpperCase()}</h5>
                            <br />

                            {screen === 1 && (
                                <>
                                    <div className="button-container">
                                        <MenuButton
                                            onClick={() => navigate('/facturacion/BO')}
                                            icon="assignment"
                                            leyenda1='Facturación'
                                            leyenda2=''
                                            color='blue darken-4'
                                        />
                                        <MenuButton
                                            onClick={() => navigate('/bancos/BO')}
                                            icon="monetization_on"
                                            leyenda1='Bancos'
                                            leyenda2=''
                                            color='black'
                                        />
                                        <MenuButton
                                            onClick={() => navigate('/noDisp')}
                                            icon="monetization_on"
                                            leyenda1='Conc. Bcria.'
                                            leyenda2=''
                                            color='blue darken-4'
                                        />
                                    </div>

                                    <div className="button-container" style={{ margin: '16px' }}>
                                        <MenuButton
                                            onClick={() => navigate('/noDisp')}
                                            icon="people"
                                            leyenda1='Equipo'
                                            leyenda2=''
                                            color='black'
                                        />
                                        <MenuButton
                                            onClick={() => navigate('/noDisp')}
                                            icon="business"
                                            leyenda1='Dirección'
                                            leyenda2=''
                                            color='blue darken-4'
                                        />
                                    </div>

                                    <div className="button-container">
                                        <MenuButton
                                            onClick={() => setScreen(2)}
                                            icon="add_circle"
                                            leyenda1='Más'
                                            leyenda2=''
                                            color='black'
                                        />
                                    </div>

                                    <div className='col s12 center' id='btn-close-sesion' style={{ marginTop: '24px' }}>
                                        <button className='btn waves-effect waves-light' type='button' onClick={() => handleCloseSesion()}>
                                            CERRAR SESION
                                        </button>
                                    </div>
                                </>
                            )}

                            {screen === 2 && (
                                <>
                                    <div className="button-container">
                                        <MenuButton
                                            onClick={() => navigate('/oficinaContable/BO')}
                                            icon="monetization_on"
                                            leyenda1='Oficina'
                                            leyenda2='Contable'
                                            color='blue darken-4'
                                        />
                                        <MenuButton
                                            onClick={() => navigate('/noDisp')}
                                            icon="business_center"
                                            leyenda1='Oficina'
                                            leyenda2='Legal'
                                            color='black'
                                        />
                                        <MenuButton
                                            onClick={() => navigate('/noDisp')}
                                            icon="dashboard"
                                            leyenda1='Auditoría'
                                            leyenda2='Interna'
                                            color='blue darken-4'
                                        />
                                    </div>

                                    <div className="button-container" style={{ margin: '16px' }}>
                                        <MenuButton
                                            onClick={() => navigate('/noDisp')}
                                            icon="assignment"
                                            leyenda1='Certificaciones'
                                            leyenda2=''
                                            color='black'
                                        />
                                        <MenuButton
                                            onClick={() => navigate('/noDisp')}
                                            icon="camera_alt"
                                            leyenda1='Medios'
                                            leyenda2=''
                                            color='blue darken-4'
                                        />
                                    </div>

                                    <div className="button-container">
                                        <MenuButton
                                            onClick={() => setScreen(3)}
                                            icon="add_circle"
                                            leyenda1='Más'
                                            leyenda2=''
                                            color='black'
                                        />
                                    </div>

                                    <div className='col s12 center' id='btn-close-sesion' style={{ marginTop: '24px' }}>
                                        <button className='btn waves-effect waves-light' type='button' onClick={() => setScreen(1)}>
                                            VOLVER
                                        </button>
                                    </div>
                                </>
                            )}

                            {screen === 3 && (
                                <>
                                    <div className="button-container">
                                        <MenuButton
                                            onClick={() => navigate('/compras/BO')}
                                            icon="attach_money"
                                            leyenda1='Compras'
                                            leyenda2=''
                                            color='blue darken-4'
                                        />
                                        <MenuButton
                                            onClick={() => navigate('/pagos/BO')}
                                            icon="assignment"
                                            leyenda1='Pagos'
                                            leyenda2=''
                                            color='black'
                                        />
                                        <MenuButton
                                            onClick={() => navigate('/noDisp')}
                                            icon="dashboard"
                                            leyenda1='Cuentas Ctes.'
                                            leyenda2=''
                                            color='blue darken-4'
                                        />
                                    </div>

                                    <div className="button-container" style={{ margin: '16px' }}>
                                        <MenuButton
                                            onClick={() => navigate('/noDisp')}
                                            icon="camera_alt"
                                            leyenda1='Medios'
                                            leyenda2=''
                                            color='black'
                                        />
                                        <MenuButton
                                            onClick={() => navigate('/noDisp')}
                                            icon="pie_chart"
                                            leyenda1='Métricas'
                                            leyenda2=''
                                            color='blue darken-4'
                                        />
                                    </div>

                                    <div className="button-container">
                                        <MenuButton
                                            onClick={() => setScreen(1)}
                                            icon="add_circle"
                                            leyenda1='Más'
                                            leyenda2=''
                                            color='black'
                                        />
                                    </div>

                                    <div className='col s12 center' id='btn-close-sesion' style={{ marginTop: '24px' }}>
                                        <button className='btn waves-effect waves-light' type='button' onClick={() => setScreen(2)}>
                                            VOLVER
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export { BienvenidaBO }
