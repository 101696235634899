// importando hooks y servicios necesarios
import { useState, useEffect } from 'react';
import { empresasPrepagaServices } from '../../Registro/services/empresasPrepaga.service';
import { obrasSocialesServices } from '../../Registro/services/obrasSociales.service';
import axios from 'axios';

// declaracion del custom hook
const useConfigPaciente = () => {
    // GESTION DE CONFIGURACION DE CUENTA DE UN PACIENTE: OBRAS SOCIALES, EMPRESAS PREPAGA
    // estados que almacenaran datos traidos del backend, especificamente las empresas prepaga y las obras sociales existentes en la base de datos
    const [todasEmpresasPrepaga, setTodasEmpresasPrepaga] = useState([]);
    const [todasObrasSociales, setTodasObrasSociales] = useState([]);
    // efectos para traer los arrays del backend 
    useEffect(() => {
        const traerObrasSociales = async () => {
            const obrasSocialesTraidas = await obrasSocialesServices.getAll();
            setTodasObrasSociales(obrasSocialesTraidas);
        };
        traerObrasSociales();
    }, []);

    useEffect(() => {
        const traerEmpresasPrepaga = async () => {
            const empresasTraidas = await empresasPrepagaServices.getAll();
            setTodasEmpresasPrepaga(empresasTraidas);
        }
        traerEmpresasPrepaga();
    }, []);

    const [OSElegida, setOSElegida] = useState(0); // las elegidas por el usuario
    const [EPElegida, setEPElegida] = useState(0)
    // estados que controlan si el usuario indico si tiene obra social o empresa prepaga o ambas, y ademas 
    // en funcion de esa informacion, mostrar los planes correspondientes que puede elegir
    const [obraSocialYPrepaga, setObraSocialYPrepaga] = useState('');
    const [planesPosibles, setPlanesPosibles] = useState([]);
    const [planElegido, setPlanElegido] = useState(0)
    const [nroAfiliado, setNumeroAfiliado] = useState('')

    // cada vez que el usuario cambie de opcion en los selects de empresa o de obra social, se ejecuta este efecto
    // que lo que hace es que si el usuario marco que solo tiene empresa prepaga, setee obra social como null
    // y viceversa. Despues, en funcion de los valores de empresa y obra social, hace la llamada a la API
    // para traer los planes que correspondan a las opciones elegidas por el usuario
    useEffect(() => {
        const traerPlanesPosibles = async () => {
            // si el usuario marco solo empresaprepaga
            if (obraSocialYPrepaga === "1") {
                // handleRespuestaRegistro('IdObraSocial', null);
                setOSElegida(0)
            };

            // si el usuario marco solo obra social
            if (obraSocialYPrepaga === "2") {
                // handleRespuestaRegistro('IdEmpresaPrepaga', null);
                setEPElegida(0)
            }

            setPlanElegido(0)
            setNumeroAfiliado("")

            const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:4000'; // declaracion de la variable de entorno de la API
            // traer los planes posibles para lo que haya marcado el usuario
            if (OSElegida !== 0 || EPElegida !== 0) {
                const planesTraidos = await axios.get(`${baseURL}/api/plataforma_medica/numerosPlanes/deObraSocialYDeEmpresaEspecifica/${OSElegida}/${EPElegida}`);
                // console.log("que pasa ac: ", planesTraidos)
                setPlanesPosibles(planesTraidos.data)
            }
        };
        traerPlanesPosibles();
    }, [EPElegida, OSElegida]);

    // cada vez que el usuario elija una opcion distinta en el select de si tiene empresa prepaga u obra social, 
    // se resetean dichos valores por mas de que esté este bug de que se sigue apareciendo como marcado en el select
    useEffect(() => {
        setOSElegida(0)
        setEPElegida(0)
        setPlanElegido(0)
        setNumeroAfiliado("")
    }, [obraSocialYPrepaga]);

    // retornando los datos traidos
    return {
        todasEmpresasPrepaga,
        todasObrasSociales,
        OSElegida,
        setOSElegida,
        EPElegida,
        setEPElegida,
        obraSocialYPrepaga,
        setObraSocialYPrepaga,
        planesPosibles,
        planElegido,
        setPlanElegido,
        nroAfiliado,
        setNumeroAfiliado
    }
};

export { useConfigPaciente }