import axios from "axios"
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000'; // declaracion de variable de entorno de la API

// traer todos los paises existentes en la base de datos
const getAllPaises = async () => {
    const paisesTraidos = await axios.get(`${apiUrl}/api/plataforma_medica/lugares/paises`);
    const paises = paisesTraidos.data;
    return paises
}

// traer todos los estados de un pais
const getEstadosByPais = async (idPais) => {
    const estadosTraidos = await axios.get(`${apiUrl}/api/plataforma_medica/lugares/estadosDePais/${idPais}`);
    const estados = estadosTraidos.data;
    return estados
}

// traer todas las ciudades de un estado (provincia)
const getCiudadesByEstado = async (idEstado) => {
    const ciudadesTraidos = await axios.get(`${apiUrl}/api/plataforma_medica/lugares/ciudadesDeEstado/${idEstado}`);
    const ciudades = ciudadesTraidos.data;
    return ciudades
}

// traer todas las ciudades existentes en la base de datos, sin tomar en cuenta estado o pais
const getAllCiudades = async () => {
    const ciudadesTraidos = await axios.get(`${apiUrl}/api/plataforma_medica/lugares/ciudades`);
    const ciudades = ciudadesTraidos.data;
    return ciudades
}

const getCiudadesPorEstadoDisponible = async () => {
    const ciudadesTraidos = await axios.get(`${apiUrl}/api/plataforma_medica/lugares/ciudades/disponibles`);
    const ciudades = ciudadesTraidos.data;
    return ciudades
}

const getCiudadesForTurnos = async (idEspecialidad) => {
    const ciudadesForTurnos = await axios.get(`${apiUrl}/api/plataforma_medica/lugares/ciudades/forTurnos/${idEspecialidad}`)
    const ciudades = ciudadesForTurnos.data;
    return ciudades
}

const ciudadesService = {
    getAllPaises,
    getEstadosByPais,
    getCiudadesByEstado,
    getAllCiudades,
    getCiudadesPorEstadoDisponible,
    getCiudadesForTurnos
}

export { ciudadesService }