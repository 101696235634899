import React from 'react';
import "./PreguntasSIyNO.css";

// Creacion del componente PreguntasSIyNO, este coponente basicamente representara todas aquellas 
// preguntas cuyasrespuestas consistan en un SI o en un NO, como por ejemplo: SOS MEDICO? SI/NO, 
// se pasan como propiedades la pregunta a realizar, la respuesta que se va a almacenar cuando el usuario de 
// click en el boton, y la funcion que perita actualizar el estado correspondiente
const PreguntasSIyNO = ({ pregunta, handleRespuesta }) => {
    return (
        <div className='pregunta-container'>
            <span className="pregunta">{pregunta}</span>
            <div className='botones-container'>
                <button
                    type="button"
                    className="si"
                    onClick={() => handleRespuesta(true)}
                >
                    SI
                </button>

                <button
                    type="button"
                    className="no"
                    onClick={() => handleRespuesta(false)}
                >
                    NO
                </button>
            </div>
            <br></br>
        </div>
    );
};

export { PreguntasSIyNO };