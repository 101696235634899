import axios from "axios"
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000'; // declaracion de variable de entorno de la API

const updateProf = async (token, data, id) => {
    try {
        const response = await axios.put(`${apiUrl}/api/plataforma_medica/prof_esp/update`, data, { headers: { Authorization: `Bearer ${token}` } });
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}

const configProfServices = {
    updateProf
}

export { configProfServices }