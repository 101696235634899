// servicio que trae las ofertas de turno hechas a un paciente, que no hayan sido ni aceptadas ni rechazadas por el mismo
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';  // declaracion de variable de entorno de la API

const getOfertasTurnoPac = async (idPac, token) => {
    try {
        const ofertasTraidas = await axios.get(`${apiUrl}/api/plataforma_medica/turnos/turnosConOfertaForUser/`, { headers: { Authorization: `Bearer ${token}` } });
        return ofertasTraidas.data
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}

const ofertasTurnosServices = {
    getOfertasTurnoPac
}

export { ofertasTurnosServices }