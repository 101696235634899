import { useEffect, useState } from "react";

const useIp = () => {
    const [ip, setIp] = useState(null);

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then((response) => response.json())
            .then((data) => setIp(data.ip));
    }, []);

    return ip;
}

export default useIp;