const useFecha = () => {
    // para convertir la fecha a un formato legible
    const formatDate = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    };

    // para devolver fecha y hora actuales donde se requiera mostrarlas
    const fecha = new Date(); // Obtener la fecha actual
    const fechaString = formatDate(fecha); // Formatear la fecha
    const hora = fecha.toLocaleTimeString(); // Obtener la hora actual en formato legible

    // para los limites de fecha en la asignacion de un turno
    const minDate = new Date();
    const maxDate = new Date();
    maxDate.setHours(maxDate.getHours() + 72);

    // para hora minima y hora maxima, pero no funciono :(
    const now = new Date();
    const minTime = new Date(now);
    minTime.setHours(now.getHours() + 1); // Establece el mínimo de 1 hora desde la hora actual
    minTime.setMinutes(0); // Establece los minutos a cero

    const maxTime = new Date(now);
    maxTime.setHours(now.getHours() + 72); // Establece el máximo de 72 horas desde la hora actual

    return {
        fechaString,
        hora,
        minDate,
        maxDate,
        minTime,
        maxTime
    }
};

export { useFecha }