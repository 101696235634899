import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import "./BotonConfiguracionCuenta.css";

const BotonConfiguracionCuenta = ({ usuarioEnSesion, setEstaEnAjustes }) => {
    const navigate = useNavigate();

    // si el usuario hace click en el boton de ajustes se ejecuta esta funcion
    const handleActivoConfiguracion = () => {
        setEstaEnAjustes(true); // indicar que el usuario entro a ajustes
        if (usuarioEnSesion.TipoUsuario === 'Estandar') {
            navigate('/configuracionPaciente') // si es paciente se dirige a esta ruta
        } else {
            navigate('/configuracionMedico') // si es profesional se dirige a esta ruta
        }
    };

    return (
        <a
            data-tooltip-id="my-tooltip-config"
            data-tooltip-content="Configuración de Cuenta"
            id="boton-config-cta"
        >
            <button className='btn waves-effect waves-light grey darken-2' id="boton-config-cta" onClick={handleActivoConfiguracion}>
                <i className='medium material-icons' id="icono-config-cta">settings</i>
            </button>
            <Tooltip
                id='my-tooltip-config'
            />
        </a>


    );
};

export { BotonConfiguracionCuenta }