// servicio que trae del backend todos los lugares de atencion medica existentes en la base de datos
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';  // declaracion de variable de entorno de la API

const getAll = async () => {
    const todosAtencionLugares = await axios.get(`${apiUrl}/api/plataforma_medica/atencionLugares/disps`);
    const atencionLugares = todosAtencionLugares.data;
    return atencionLugares;
};

const atencionLugaresServices = {
    getAll
};

export { atencionLugaresServices }