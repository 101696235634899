// importando hooks y servicios necesarios
import { useState, useEffect } from "react";
import { especialidadesServices } from "../../Registro/services/especialidades.service";
import { atencionLugaresServices } from "../../Registro/services/atencionLugares.service";

// declaracion del custom hook
const useConfigProfesional = () => {
    // GESTION DE LAS ESPECIALIDADES DEL PROFESIONAL
    const [todasEspecialidades, setTodasEspecialidades] = useState([]); // almacenaremos aqui las especialidades traidas del backend
    const [errorEspecialidades, setErrorEspecialidades] = useState(false); // indicar si el usuario escogio especialidades
    const [especialidades, setEspecialidades] = useState([0]); //permitir agregar y eliminar especialidades si es un profesional

    const handleEspecialidadesChange = (e, index) => {
        const list = [...especialidades];
        list[index] = parseInt(e.target.value, 10);
        setEspecialidades(list);
    }
    const handleAddEspecialidad = () => {
        setEspecialidades([...especialidades, 0]);
    }
    const handleRemoveEspecialidad = (index) => {
        const list = [...especialidades];
        list.splice(index, 1);
        setEspecialidades(list);
    }
    // traer las especialidades existentes en la base de datos
    useEffect(() => {
        const traerEspecialidades = async () => {
            const especialidadesTraidas = await especialidadesServices.getAll();
            setTodasEspecialidades(especialidadesTraidas)
        }
        traerEspecialidades()
    }, []);


    // GESTION DEL ARCHIVO QUE SUBA UN PROFESIONAL
    // permitir agregar y eliminar archivos si se trata de un profesional
    const [files, setFiles] = useState(null);
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0]; // Solo toma el primer archivo seleccionado
        if (selectedFile) {
            const newFile = {
                name: selectedFile.name,
                size: selectedFile.size,
                path: URL.createObjectURL(selectedFile),
            };
            setFiles(newFile);
        };
    };

    const handleRemoveFile = () => {
        setFiles(null); // Elimina el archivo actual
    };

    // GESTION DE LOS LUGARES DE ATENCION DEL PROFESIONAL
    const [todosAtencionLugares, setTodosAtencionLugares] = useState([]); // todos los traidos del backend: [{}, {}, {}]
    const [atencionLugares, setAtencionLugares] = useState([{ id: 0, nombre: '', direccion: '', ciudad: 0 }]); // los elegidos por el usuario [1,2,3]
    const [errorLAs, setErrorLAs] = useState(false)

    useEffect(() => {
        const traerAtencionLugares = async () => {
            const atencionLugaresTraidos = await atencionLugaresServices.getAll();
            setTodosAtencionLugares(atencionLugaresTraidos)
        }
        traerAtencionLugares();
    }, [])

    const handleAtencionLugaresChange = (e, index) => {
        const list = [...atencionLugares];
        list[index].id = parseInt(e.target.value, 10);
        setAtencionLugares(list);
    }

    const handleNombreChange = (e, index) => {
        const list = [...atencionLugares];
        list[index].nombre = e.target.value;
        setAtencionLugares(list);
    }

    const handleDireccionChange = (e, index) => {
        const list = [...atencionLugares];
        list[index].direccion = e.target.value;
        setAtencionLugares(list);
    }

    const handleCiudadChange = (e, index) => {
        const list = [...atencionLugares];
        list[index].ciudad = parseInt(e.target.value);
        setAtencionLugares(list);
    }

    const handleAddAtencionLugar = () => {
        setAtencionLugares([...atencionLugares, { id: 0, nombre: '', direccion: '', ciudad: 0 }]);
    }

    const handleRemoveAtencionLugar = (index) => {
        const list = [...atencionLugares];
        list.splice(index, 1);
        setAtencionLugares(list);
    }


    // funcion da validacion de ingreso de numero telefonico, que sean maximo 15 caracteres, minimo 9 caracteres
    // sin espacios de por medio, y sin simbolos - ni e ni +.
    function handleTelefonoKeyPress(e) {
        const allowedCharacters = /^[0-9\b]+$/; // Expresión regular para permitir solo dígitos y la tecla de retroceso (\b)
        if (!allowedCharacters.test(e.key)) {
            e.preventDefault(); // Evitar la acción por defecto si se presiona una tecla no permitida
        }
    }

    return {
        todasEspecialidades,
        especialidades,
        handleAddEspecialidad,
        handleEspecialidadesChange,
        handleRemoveEspecialidad,
        files,
        handleFileChange,
        handleRemoveFile,
        errorEspecialidades,
        setErrorEspecialidades,
        todosAtencionLugares,
        atencionLugares,
        handleAddAtencionLugar,
        handleAtencionLugaresChange,
        handleRemoveAtencionLugar,
        errorLAs,
        setErrorLAs,
        handleNombreChange,
        handleDireccionChange,
        handleCiudadChange,
        handleTelefonoKeyPress,
        setEspecialidades,
        setAtencionLugares,
        setFiles
    }
};

export { useConfigProfesional }