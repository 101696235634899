import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HomeClientes = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/')
    }

    return (
        <>
            <div>HomeClientes</div>
            <div className='col s12 center'>
                <button
                    className='btn waves-effect waves-light'
                    type="button"
                    onClick={handleBack}
                    style={{ marginTop: "20px" }}
                >
                    VOLVER
                </button>
            </div>
        </>

    )
}

export { HomeClientes }