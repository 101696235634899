import React from 'react';
import "./Footer.css";

// importo el logo de ENGTRADE de la carpeta de imagenes
// import logoEngtrade from "../../../images/ENGTRADE_LOGO.jpeg";
import logoEngtrade from "../../../images/ENGTRADE_LOGO.jpeg"

// creo el pie de pagina el cual va a ser el mismo para todas las pantallas de la aplicacion
const Footer = () => {
    return (
        <footer className="pie_pagina">
            <span className="texto_pie_pagina">Sistema Desarrollado por</span>
            <img src={logoEngtrade} alt="Logo de ENGTRADE" className="logo_engtrade" height="52px"></img>
            <span className="texto_pie_pagina"><b>©2020-2023</b></span>
        </footer>
    );
};

export { Footer }