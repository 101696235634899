import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';

const gestNotif = async (data, token) => {
    try {
        const response = await axios.put(`${apiUrl}/api/plataforma_medica/profesionales/gestNotif`, data, { headers: { Authorization: `Bearer ${token}` } });
        return response.data;
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}

const notifProfServices = {
    gestNotif,
}

export { notifProfServices }