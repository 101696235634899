// servicio que trae del backend todos los reclutadores existentes en la base de datos
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';  // declaracion de variable de entorno de la API

const getAll = async () => {
    const reclutadoresTraidos = await axios.get(`${apiUrl}/api/plataforma_medica/reclutadores`);
    const reclutadores = reclutadoresTraidos.data;
    return reclutadores;
};

const reclutadoresServices = {
    getAll
};

export { reclutadoresServices }