import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from "react-tooltip";
import "./BotonPreguntasFrec.css";
import useIp from '../IniciarSesion/hooks/useIp.js';
import { useEvento } from '../../../../hooks/useEvento.js';

const BotonPreguntasFrec = ({ usuarioEnSesion, setEstaEnPregFrec, token }) => {
    const navigate = useNavigate();

    const ip = useIp()
    const { crearE } = useEvento()


    // si el usuario hace click en el boton de ajustes se ejecuta esta funcion
    const handleActivoPregFrec = () => {
        if (usuarioEnSesion.TipoUsuario === 'Estandar') {// si es paciente se dirige a esta ruta
            crearE({ id_tipo: 22, id_resultado: 1, ip_user: ip, id_usuario: usuarioEnSesion?.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion?.usuarioComun ? usuarioEnSesion.Id : null }, token)
            window.open('https://www.doctorfy.com.ar/faq.html', '_blank'); // abre una nueva ventana
        } else {
            setEstaEnPregFrec(true); // indicar que el usuario entro a ajustes
            crearE({ id_tipo: 22, id_resultado: 1, ip_user: ip, id_usuario: usuarioEnSesion?.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion?.usuarioComun ? usuarioEnSesion.Id : null }, token)
            navigate('/preguntasFrecuentesProf') // si es profesional se dirige a esta ruta
        }
    };

    return (
        <>
            <a
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Preguntas Frecuentes"
                id="boton-preg-frec"
            >
                <button
                    className='btn waves-effect waves-light blue darken-2'
                    id="boton-preg-frec"
                    onClick={handleActivoPregFrec}

                ><i className='medium material-icons' id="icono-preg-frec">help</i>
                </button>
                <Tooltip
                    id='my-tooltip'
                />
            </a>
        </>
    );
};

export { BotonPreguntasFrec }