// importando funcionalidades, hooks, librerias, y custom hooks necesarios
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useConfigProfesional } from '../../hooks/useConfigProfesional';
import axios from 'axios';

// importando estilos e imagenes
import './ConfigsProfesional.css';
import logoPTM from "../../../../images/newLogoPTM.jpeg"
import { useNavigate } from 'react-router-dom';
import { useLugares } from '../../hooks/useLugares';
import { configProfServices } from '../services/configProf.service';
import { SessionExpired } from '../../../components/SessionExpired/SessionExpired';
import { createEvento } from '../../../../services/evento.service.js';
import useIp from '../../../PantallaInicial/components/IniciarSesion/hooks/useIp.js';
import { useEvento } from '../../../../hooks/useEvento.js';

// declaracion del componente de configuracion para profesionales de la salud
const ConfigsProfesional = ({ apiUrl, usuarioEnSesion, token, setEstaEnSesion, setUsuarioEnSesion, setToken, setEstaEnAjustes, setEstaEnContacto, setEstaEnPregFrec }) => {
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const ip = useIp()

    const { handleCampoLlego, campoLlego, crearE } = useEvento()

    const {
        todasEspecialidades,
        especialidades,
        handleAddEspecialidad,
        handleEspecialidadesChange,
        handleRemoveEspecialidad,
        files,
        handleFileChange,
        handleRemoveFile,
        errorEspecialidades,
        setErrorEspecialidades,
        todosAtencionLugares,
        atencionLugares,
        handleAddAtencionLugar,
        handleAtencionLugaresChange,
        handleRemoveAtencionLugar,
        errorLAs,
        setErrorLAs,
        handleCiudadChange,
        handleDireccionChange,
        handleNombreChange,
        setEspecialidades,
        setAtencionLugares,
        setFiles
    } = useConfigProfesional(); // trayendo los estados y funcionalidades necesarias del custom hook useConfigProfesional();

    const {
        paises,
        estadosPaisEspecifico,
        ciudadesEstadoEspecifico,
        setPaisElegido,
        setCiudadElegida,
        setEstadoElegido,
        paisElegido,
        ciudadElegida,
        estadoElegido
    } = useLugares(); // trayendo los estados y funcionalidades necesarias del custom hook useLugares

    useEffect(() => {
        if (ip !== null) {
            crearE({ id_tipo: 28, id_resultado: 1, ip_user: ip, id_usuario: usuarioEnSesion.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion.usuarioComun ? usuarioEnSesion.Id : null }, token)
        }
    }, [ip])

    useEffect(() => {
        const traerCiudadEstadoPaisInicial = async () => {
            if (usuarioEnSesion) {
                const data = await axios.get(`${apiUrl}/api/plataforma_medica/lugares/ciudad_estado_pais/${usuarioEnSesion.IdCiudad}`)
                const data2 = data.data
                setPaisElegido(data2.IdPais)
                setCiudadElegida(data2.IdCiudad)
                setEstadoElegido(data2.IdEstado)

                // setFiles({
                //     name: usuarioEnSesion.Archivos[0].Nombre,
                //     size: usuarioEnSesion.Archivos[0].Tamanio,
                //     path: usuarioEnSesion.Archivos[0].Directorio,
                // })
            }
        }
        traerCiudadEstadoPaisInicial()
    }, [usuarioEnSesion])
    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
            setEstaEnAjustes(true)
        }
    }, [])

    useEffect(() => {
        // Cuando el componente carga, establece las especialidades del usuario en el estado
        if (usuarioEnSesion && usuarioEnSesion.Especialidades) {
            const especialidadesIds = usuarioEnSesion.Especialidades.map(especialidad => especialidad.IdEspecialidad);
            setEspecialidades(especialidadesIds);
        }
    }, [usuarioEnSesion]);

    // SETEANDO VALORES INCIALES DEL FORMULARIO DE CONFIGURACION
    const setearValores = (usuarioEnSesion) => {
        if (usuarioEnSesion) {
            setValue('email', usuarioEnSesion?.Correo || '');
            setValue('idCiudad', usuarioEnSesion?.Ciudad || 0);
            setValue('domicilio', usuarioEnSesion?.Domicilio || '');
            setValue('numeroTelefono', usuarioEnSesion?.NumeroTelefono?.replace(/\+54/g, '') || '');
            setValue('nuevasEspecialidades', usuarioEnSesion?.Especialidades || []);
            setValue('nuevosArchivos', usuarioEnSesion?.Archivos || []);
        }
    }

    // inicialmente, en el formulario, se setearan estos valores precargados, los cuales son traidos desde el backend
    useEffect(() => {
        setearValores(usuarioEnSesion) // Actualizar los valores de los campos cuando se cargue el usuario
    }, [usuarioEnSesion]);

    const fetchAtencionLugaresProf = async () => {
        if (usuarioEnSesion) {
            try {
                const response = await axios.get(`${apiUrl}/api/plataforma_medica/prof_LAs/byMedico/forConfig`, { headers: { Authorization: `Bearer ${token}` } });
                const datos = response.data;

                const nuevosAtencionLugares = datos.map(item => ({
                    id: item.Id,
                    nombre: item.Nombre,
                    direccion: item.Direccion,
                    ciudad: item.IdCiudad
                }));

                setAtencionLugares(nuevosAtencionLugares);
            } catch (error) {
                console.error('Error al obtener datos:', error);
            }
        }
    }

    useEffect(() => {
        fetchAtencionLugaresProf()
    }, [usuarioEnSesion])

    const validateAtencionLugares = (atencionLugares) => {
        // Si el array tiene solo un elemento y cumple con las condiciones específicas, se considera válido
        if (atencionLugares.length === 1 &&
            atencionLugares[0].id === 0 &&
            atencionLugares[0].nombre === '' &&
            atencionLugares[0].direccion === '' &&
            (atencionLugares[0].ciudad === '' || atencionLugares[0].ciudad === 0)
        ) {
            return false;
        }

        // Realizar la validación si hay más de un elemento en el array
        return atencionLugares.some(element => (
            element.id === 0 ||
            element.nombre === '' ||
            element.direccion === '' ||
            element.ciudad === '' ||
            element.ciudad === 0
        ));
    };

    // ERRORES DE FORMULARIO
    const [errorResidencia, setErrorResidencia] = useState(false); // estado de validacion en caso de que haya seleccionado pais, estado y ciudad o si no lo hizo
    const [responseError, setResponseError] = useState("") // estado que establece si tiro error al actualizar el profesional por parte del backend
    const [tokenVencido, setTokenVencido] = useState(false)

    // esta funcion se ejecutara en cuanto el usuario haga click en ACTUALIZAR, esta funcion hace validaciones de errores
    // y envia los datos al backend
    const onSubmit = async (data, e) => {
        e.preventDefault();

        if (data.password === "") {
            delete data.password
        }
        // declarando el objeto que se va a enviar al backend
        const newData = {
            ...data,
            // nuevosArchivos: files,
            nuevosArchivos: null,
            nuevasEspecialidades: especialidades,
            idCiudad: ciudadElegida,
            nuevosAtencionLugares: atencionLugares,
            ip: ip
        }

        // validando si el usuario completo toda la informacion referida a su residencia, es decir, pais/estado/ciudad
        if (newData.idCiudad === 0) {
            setErrorResidencia(true)
            return;
        }
        setErrorResidencia(false)

        // validando si el usuario escogio especialidades o no 
        // nota: la solucion mas simple que encontre al problema de traer las especialidades precargadas del backend
        // fue simplemente resetear las especialidades y que el usuario elija de nuevo 
        if (newData.nuevasEspecialidades.includes(0) ||
            new Set(newData.nuevasEspecialidades).size !== newData.nuevasEspecialidades.length ||
            newData.nuevasEspecialidades.length === 0
        ) {
            setErrorEspecialidades(true)
            return;
        }
        setErrorEspecialidades(false);

        // validando si el usuario escogio de manera correcta lugares de atencion
        if (validateAtencionLugares(newData.nuevosAtencionLugares)) {
            setErrorLAs(true);
            return;
        }
        setErrorLAs(false);

        newData.nuevosAtencionLugares = (newData.nuevosAtencionLugares.length === 1 && newData.nuevosAtencionLugares[0].id === 0) ? [] : newData.nuevosAtencionLugares;
        newData.nuevosAtencionLugares.forEach(element => delete element.id);

        // aca se lleva a cabo la actualizacion de los datos
        const response = await configProfServices.updateProf(token, newData, usuarioEnSesion?.Id)
        if (response.error) {
            if (response.error === "TOKEN VENCIDO.") {
                setTokenVencido(true);
                return;
            }
            setResponseError(response.error)
            return
        }
        setResponseError("")
        setUsuarioEnSesion(response)
        window.localStorage.setItem("DoctorFy", JSON.stringify(response))
        reset(); // se resetean los datos del formulario
        resetearValores()
        setEstaEnAjustes(false);
        navigate("/bienvenida")
    }

    const resetearValores = () => {
        setErrorEspecialidades(false);
        setErrorLAs(false);
        setResponseError("")
        setErrorResidencia(false);
        setPaisElegido(0)
        setEstadoElegido(0)
        setCiudadElegida(0)
        setEstaEnAjustes(false)
    }

    // esta funcion se ejecuta si el usuario hace click en el boton volver
    const handleBack = () => {
        reset();
        setEstaEnAjustes(false);
        resetearValores()
        navigate(-1)
        if (campoLlego !== null) {
            crearE({ id_tipo: 29, id_resultado: 2, ip_user: ip, id_form: 5, id_campo_llego: campoLlego, id_usuario: usuarioEnSesion.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion.usuarioComun ? usuarioEnSesion.Id : null }, token)
            crearE({ id_tipo: 31, id_resultado: 2, ip_user: ip, id_form: 5, id_campo_llego: campoLlego, id_usuario: usuarioEnSesion.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion.usuarioComun ? usuarioEnSesion.Id : null }, token)
        } else {
            crearE({ id_tipo: 31, id_resultado: 1, ip_user: ip, id_usuario: usuarioEnSesion.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion.usuarioComun ? usuarioEnSesion.Id : null }, token)
        }
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="form-config-container row">
                        <form className="col s12" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className='col s12 center'>
                                    <div className="logo-container center">
                                        <img src={logoPTM} alt="Logo PTM" width="500" />
                                    </div>
                                    <h4 id="header-form-registro">CONFIGURACION</h4>
                                </div>

                                <div className="col s6 l4">
                                    <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="email">Correo Electronico</label>
                                    <input style={{ fontWeight: "bold", marginBottom: "0px" }} id="email" type="email" name="email" className="validate" required {...register('email')} onKeyDown={() => handleCampoLlego(36)} />
                                </div>

                                <div className="col s6 l4">
                                    <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="pais">País de Residencia</label>
                                    <select className="browser-default validate" style={{ marginBottom: "1px", fontWeight: "bold" }} id="pais" name="pais" required value={paisElegido} onChange={(e) => setPaisElegido(parseInt(e.target.value))} onChangeCapture={() => handleCampoLlego(37)}>
                                        <option value={0} disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                        {paises.map((e, index) => (
                                            <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                        ))}
                                    </select>
                                </div>

                                {paisElegido !== 0 && (
                                    <div className="col s6 l4">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="provincia">Provincia de Residencia</label>
                                        <select className="browser-default validate" style={{ marginBottom: "1px", fontWeight: "bold" }} id="provincia" name="provincia" value={estadoElegido} required onChange={(e) => setEstadoElegido(parseInt(e.target.value))} onChangeCapture={() => handleCampoLlego(38)} >
                                            <option value={0} disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                            {estadosPaisEspecifico.map((e, index) => (
                                                <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                            ))}
                                        </select>
                                    </div>
                                )}

                                {estadoElegido !== 0 && (
                                    <div className="col s6 l4">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="cuidad">Ciudad de Residencia</label>
                                        <select className="browser-default validate" style={{ marginBottom: "1px", fontWeight: "bold" }} id="ciudad" name="ciudad" required {...register('idCiudad')} value={ciudadElegida} onChange={(e) => setCiudadElegida(parseInt(e.target.value))} onChangeCapture={() => handleCampoLlego(39)}>
                                            <option value={0} disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                            {ciudadesEstadoEspecifico.map((e, index) => (
                                                <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                                {errorResidencia && (
                                    <>
                                        <span className='error-message'>ERROR. Por favor completá toda la informacion sobre tu residencia</span>
                                        <br></br>
                                    </>
                                )}

                                <div className='col s6 l4'>
                                    <label htmlFor='domicilio' style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} >Ingresá tu Domicilio</label>
                                    <input style={{ fontWeight: "bold", marginBottom: "4px" }} id="domicilio" name="domicilio" className='materialize-textarea validate' required maxLength="100"  {...register('domicilio')} onKeyDown={() => handleCampoLlego(40)} ></input>
                                </div>

                                <div className="col s6 l4">
                                    <label htmlFor="numeroTelefonico" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} > Numero de Telefono</label>
                                    <input style={{ fontWeight: "bold", marginBottom: "4px" }} type="number" id="numeroTelefonico" name="numeroTelefonico" className='validate' required {...register('numeroTelefono')} onKeyDown={() => handleCampoLlego(41)} />
                                </div>

                                {especialidades.map((especialidadId, index) => {
                                    return (
                                        <div key={index} className='col s6 l4'>
                                            <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} >
                                                Especialidad {index + 1}:
                                            </label>
                                            <select
                                                name="especialidad"
                                                value={especialidadId}
                                                onChange={(e) => handleEspecialidadesChange(e, index)}
                                                onChangeCapture={() => handleCampoLlego(42)}
                                                className='browser-default'
                                                style={{ fontWeight: "bold" }}
                                                required
                                            >
                                                <option value="0" disabled style={{ fontWeight: "bold" }}>Seleccione una especialidad</option>
                                                {todasEspecialidades.map((e, index) => {
                                                    return (
                                                        <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    )
                                })}

                                {especialidades.length > 1 && (
                                    <div className="col s12 center">
                                        <button type="button" onClick={() => handleRemoveEspecialidad(especialidades.length - 1)} className='btn waves-effect waves-light'>
                                            Eliminar Especialidad
                                        </button>
                                    </div>
                                )}

                                <div className='col s12 center'>
                                    <button type="button" onClick={handleAddEspecialidad} id="addEspecialidad" className='btn waves-effect waves-light'>
                                        Agregar Especialidad
                                    </button>
                                </div>

                                {errorEspecialidades && <div className='col s12 center'>
                                    <span className='error-message-2'>ERROR. No dejes especialidades sin elegir</span></div>}

                                {/* REGISTRAR 0 O MUCHOS LUGARES DE ATENCION */}
                                {atencionLugares.map((atencionLugar, index) => {
                                    return (
                                        <div key={index} className='col s12'>
                                            <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>
                                                Lugar de Atención {index + 1}:
                                            </label>
                                            <div className='row'>
                                                <div className='col s12'>
                                                    <select
                                                        name="atencionLugar"
                                                        style={{ fontWeight: "bold" }}
                                                        value={atencionLugar.id} // Cambiar defaultValue a value
                                                        onChange={(e) => handleAtencionLugaresChange(e, index)}
                                                        onChangeCapture={() => handleCampoLlego(43)}
                                                        className='browser-default'
                                                        required
                                                    >
                                                        <option value="0" disabled style={{ fontWeight: "bold" }}>Seleccione un lugar de atención</option>
                                                        {todosAtencionLugares.map((e, index) => {
                                                            return (
                                                                <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>

                                                {atencionLugar.id !== 0 && (
                                                    <div className='row'>
                                                        <input className='col s5 l3' type="text" name={`nombre_${index}`} placeholder="Nombre" style={{ fontWeight: "bold", margin: "0 12px" }} value={atencionLugar.nombre} onChange={(e) => handleNombreChange(e, index)} />
                                                        <input className='col s5 l3' type="text" name={`direccion_${index}`} style={{ fontWeight: "bold", margin: "0 12px" }} placeholder="Dirección" value={atencionLugar.direccion} onChange={(e) => handleDireccionChange(e, index)} />
                                                        <select name={`ciudad_${index}`} value={atencionLugar.ciudad} onChange={(e) => handleCiudadChange(e, index)} className='browser-default col s5 l3' style={{ margin: "0 12px", fontWeight: "bold" }}>
                                                            <option value="" style={{ fontWeight: "bold" }}>Seleccione una ciudad</option>
                                                            {ciudadesEstadoEspecifico.map((e, index) => (
                                                                <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                )}
                                            </div>


                                        </div>
                                    )
                                })}

                                {atencionLugares.length > 1 && (
                                    <div className="col s12 center">
                                        <button type="button" onClick={() => handleRemoveAtencionLugar(atencionLugares.length - 1)} className='btn waves-effect waves-light'>
                                            Eliminar Lugar de Atención
                                        </button>
                                    </div>
                                )}

                                <div className='col s12 center'>
                                    <button type="button" onClick={handleAddAtencionLugar} id="addLA" className='btn waves-effect waves-light'>
                                        Agregar Lugar de Atención
                                    </button>
                                </div>

                                {errorLAs && (
                                    <div className='col s12 center'>
                                        <span className='error-message-2'>ERROR. Completá toda la información referida a los lugares de atención.</span>
                                    </div>
                                )}

                                {/* <div className="file-field col s12">
                                    <div className="btn">
                                        <span>Subir Documento</span>
                                        <input type="file" onChange={handleFileChange} multiple />
                                    </div>
                                    <div className="file-path-wrapper">
                                        <input className="file-path validate" type="text" placeholder="Selecciona archivos" />
                                    </div>
                                </div>

                                {files && (
                                    <div className="col s12" style={{ marginBottom: '12px' }}>
                                        <div className="col s6 left">
                                            <p style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>{files.name}</p>
                                        </div>
                                        <div className="col s6 right" style={{ marginTop: '10px' }}>
                                            <button className="btn red" onClick={handleRemoveFile}>Eliminar</button>
                                        </div>
                                    </div>
                                )} */}

                                <div className="col s12">
                                    <label htmlFor="newPassword" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Querés cambiar tu contraseña?</label>
                                    <input onKeyDown={() => handleCampoLlego(44)} style={{ fontWeight: "bold", marginBottom: "4px" }} id="newPassword" type="password" name="newPassword" className="validate" {...register('password', {
                                        minLength: {
                                            value: 4,
                                            message: "La contraseña debe tener como mínimo 4 caracteres"
                                        },
                                        maxLength: {
                                            value: 15,
                                            message: "La contraseña debe tener como máximo 15 caracteres"
                                        }
                                    })} />
                                </div>
                                {errors.password && (
                                    <p className='error-message'>{errors.password.message}</p>
                                )}

                                {responseError !== null && <span className='error-message'>{responseError}</span>}

                                <div className='col s12 center'>
                                    <button
                                        className='btn waves-effect waves-light'
                                        type="button"
                                        onClick={handleBack}
                                        style={{ marginTop: "20px", marginBottom: '20px' }}
                                    >
                                        VOLVER
                                    </button>
                                </div>

                                <div className="col s12 center">
                                    <button className='btn waves-effect waves-light btn-update-prof' type="submit">
                                        ACTUALIZAR
                                        <i className='material-icons right large'>account_box</i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </>
            ) : (
                // Si el token expiró o no hay un usuario en sesión
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    )
};

export { ConfigsProfesional }