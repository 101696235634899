// servicio que trae del backend todas las especialidades medicas existentes en la base de datos
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';  // declaracion de variable de entorno de la API

const getAll = async () => {
    const todasEspecialidadesMedicas = await axios.get(`${apiUrl}/api/plataforma_medica/especialidades`);
    const especialidades = todasEspecialidadesMedicas.data;
    return especialidades;
};

const especialidadesServices = {
    getAll
};

export { especialidadesServices }