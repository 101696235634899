import React from 'react'
import { useNavigate } from 'react-router-dom';

const MenuButton = ({ onClick, icon, leyenda1, leyenda2, color }) => {
    const navigate = useNavigate();
    return (
        <div className="center-align" style={{ marginLeft: '8px', marginRight: '8px' }}>
            <button className={`btn btn-floating ${color}`} onClick={onClick}>
                <i className="material-icons">{icon}</i>
            </button>
            <p className='solicitarTurnop'>{leyenda1}</p>
            <p className='solicitarTurnop'>{leyenda2}</p>
        </div>
    )
}

export { MenuButton }