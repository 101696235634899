import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import logoPTM from '../../images/newLogoPTM.jpeg';
import { SessionExpired } from '../../modules/components/SessionExpired/SessionExpired.js';
import { MenuButton } from '../components/MenuButton.js';

const Compras = ({
    apiUrl,
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken
}) => {
    const navigate = useNavigate();
    const [tokenVencido, setTokenVencido] = useState(false)
    const [screen, setScreen] = useState(1)

    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="row">
                        <div className="col s12 m6 offset-m3">
                            <div className="logo-container center">
                                <img src={logoPTM} alt="Logo PTM" width="200" />
                            </div>
                            <h4 className="center-align" style={{ color: 'blue' }}>Panel de control de</h4>
                            <h5 className="center-align">{usuarioEnSesion?.user?.Login_nombre?.toUpperCase()} {usuarioEnSesion?.user?.Login_apellido?.toUpperCase()} : COMPRAS</h5>
                            <br />

                            <div className="button-container">
                                <MenuButton
                                    onClick={() => navigate('/noDisp')}
                                    icon="people"
                                    leyenda1='Proveedores'
                                    leyenda2=''
                                    color='blue darken-4'
                                />
                                <MenuButton
                                    onClick={() => navigate('/noDisp')}
                                    icon="build"
                                    leyenda1='Rubros'
                                    leyenda2=''
                                    color='black'
                                />
                                <MenuButton
                                    onClick={() => navigate('/noDisp')}
                                    icon="apps"
                                    leyenda1='Productos'
                                    leyenda2=''
                                    color='blue darken-4'
                                />
                            </div>

                            <div className="button-container" style={{ margin: '16px' }}>
                                <MenuButton
                                    onClick={() => navigate('/noDisp')}
                                    icon="add_shopping_cart"
                                    leyenda1='Orden de'
                                    leyenda2='Compra'
                                    color='black'
                                />
                                <MenuButton
                                    onClick={() => navigate('/noDisp')}
                                    icon="assignment_turned_in"
                                    leyenda1='Recepción'
                                    leyenda2=''
                                    color='blue darken-4'
                                />
                            </div>

                            <div className="button-container">
                                <MenuButton
                                    onClick={() => navigate('/noDisp')}
                                    icon="assignment"
                                    leyenda1='Orden de'
                                    leyenda2='Facturación'
                                    color='black'
                                />
                            </div>

                            <div className='col s12 center' id='btn-close-sesion' style={{ marginTop: '24px' }}>
                                <button className='btn waves-effect waves-light' type='button' onClick={() => navigate('/bienvenida/BO')}>
                                    VOLVER
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export { Compras }