import { useEffect, useState } from 'react';
import axios from 'axios';

// importo los servicios necesarios que traen las especialidades y las empresas prepaga del backend
import { empresasPrepagaServices } from '../../services/empresasPrepaga.service.js';
import { obrasSocialesServices } from '../../services/obrasSociales.service.js';
import { especialidadesServices } from '../../services/especialidades.service.js';
import { ciudadesService } from '../../services/ciudades.service.js';
import { reclutadoresServices } from '../../services/reclutadores.service.js';
import { atencionLugaresServices } from '../../services/atencionLugares.service.js';

const useFormularioRegistro = (esMedico) => {
    // GESTION DE RENDERIZADO DINAMICO DE CAMPOS DE PAIS -> PROVINCIA -> CIUDAD A TRAVES DE ESTADOS
    const [paises, setPaises] = useState([])
    const [estadosPaisEspecifico, setEstadosPaisEspecifico] = useState([]);
    const [ciudadesEstadoEspecifico, setCiudadesEstadoEspecifico] = useState([])
    const [paisElegido, setPaisElegido] = useState(0)
    const [estadoElegido, setEstadoElegido] = useState(0);
    const [ciudadElegida, setCiudadElegida] = useState(0);

    useEffect(() => {
        const traerPaises = async () => {
            const paisesTraidos = await ciudadesService.getAllPaises();
            setPaises(paisesTraidos);
        }
        traerPaises();
    }, []);

    // cada vez que cambie el pais, me setee el estado elegido en 0, y que me setee el array de estados de ese pais
    useEffect(() => {
        const traerEstadosDePais = async () => {
            const estadosTraidos = await ciudadesService.getEstadosByPais(paisElegido);
            setEstadosPaisEspecifico(estadosTraidos);
            setEstadoElegido(0)
        }
        traerEstadosDePais();
    }, [paisElegido]);

    // cada vez que cambie el estado de un pais, me setee la ciudad elegida en 0, y que me 
    // setee el array de ciudades a las del estado elegido por el que se esta registrando
    useEffect(() => {
        const traerCiudadesDeEstado = async () => {
            const ciudadesTraidas = await ciudadesService.getCiudadesByEstado(estadoElegido);
            setCiudadesEstadoEspecifico(ciudadesTraidas)
            setCiudadElegida(0)
        }
        traerCiudadesDeEstado()
    }, [estadoElegido])


    // hacer que la fecha que ingrese el usuario sea la actual o bien 100 años menos como limite a la hora de registrarse
    const fechaActual = new Date();
    const fechaMinima = new Date();
    fechaMinima.setFullYear(fechaActual.getFullYear() - 100);
    const fechaActualString = fechaActual.toISOString().split('T')[0];
    const fechaMinimaString = fechaMinima.toISOString().split('T')[0];


    // funcion da validacion de ingreso de numero telefonico, que sean maximo 15 caracteres, minimo 9 caracteres
    // sin espacios de por medio, y sin simbolos - ni e ni +.
    function handleTelefonoKeyPress(e) {
        const allowedCharacters = /^[0-9\b]+$/; // Expresión regular para permitir solo dígitos y la tecla de retroceso (\b)
        if (!allowedCharacters.test(e.key)) {
            e.preventDefault(); // Evitar la acción por defecto si se presiona una tecla no permitida
        }
    }

    // GESTION DEL REGISTRO DE PROFESIONALES DE LA SALUD
    // GESTION DE LAS ESPECIALIDADES
    const [todasEspecialidadesMedicas, setTodasEspecialidadesMedicas] = useState([]); // las traidas del backend [{}, {}, {}]
    const [especialidades, setEspecialidades] = useState([0]) // las que elija el usuario [1,2,3]

    useEffect(() => {
        const traerEspecialidadesMedicas = async () => {
            const especialidadesTraidas = await especialidadesServices.getAll();
            setTodasEspecialidadesMedicas(especialidadesTraidas);
        };
        traerEspecialidadesMedicas();
    }, []);

    const handleEspecialidadesChange = (e, index) => {
        const list = [...especialidades];
        list[index] = parseInt(e.target.value, 10);
        setEspecialidades(list);
    }
    const handleAddEspecialidad = () => {
        setEspecialidades([...especialidades, 0]);
    }
    const handleRemoveEspecialidad = (index) => {
        const list = [...especialidades];
        list.splice(index, 1);
        setEspecialidades(list);
    }

    // GESTION DE LOS LUGARES DE ATENCION
    const [todosAtencionLugares, setTodosAtencionLugares] = useState([]); // todos los traidos del backend: [{}, {}, {}]
    const [atencionLugares, setAtencionLugares] = useState([{ id: 0, nombre: '', direccion: '', ciudad: 0 }]); // los elegidos por el usuario [1,2,3]

    useEffect(() => {
        const traerAtencionLugares = async () => {
            const atencionLugaresTraidos = await atencionLugaresServices.getAll();
            setTodosAtencionLugares(atencionLugaresTraidos)
        }
        traerAtencionLugares();
    }, [])

    const handleAtencionLugaresChange = (e, index) => {
        const list = [...atencionLugares];
        list[index].id = parseInt(e.target.value, 10);
        setAtencionLugares(list);
    }

    const handleNombreChange = (e, index) => {
        const list = [...atencionLugares];
        list[index].nombre = e.target.value;
        setAtencionLugares(list);
    }

    const handleDireccionChange = (e, index) => {
        const list = [...atencionLugares];
        list[index].direccion = e.target.value;
        setAtencionLugares(list);
    }

    const handleCiudadChange = (e, index) => {
        const list = [...atencionLugares];
        list[index].ciudad = parseInt(e.target.value);
        setAtencionLugares(list);
    }

    const handleAddAtencionLugar = () => {
        setAtencionLugares([...atencionLugares, { id: 0, nombre: '', direccion: '', ciudad: 0 }]);
    }

    const handleRemoveAtencionLugar = (index) => {
        const list = [...atencionLugares];
        list.splice(index, 1);
        setAtencionLugares(list);
    }

    // GESTION DEL ARCHIVO QUE SUBA UN PROFESIONAL
    // permitir agregar y eliminar archivos si se trata de un profesional
    const [files, setFiles] = useState(null);
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0]; // Solo toma el primer archivo seleccionado
        if (selectedFile) {
            const newFile = {
                name: selectedFile.name,
                size: selectedFile.size,
                path: URL.createObjectURL(selectedFile),
            };
            setFiles(newFile);
        };
    };

    const handleRemoveFile = () => {
        setFiles(null); // Elimina el archivo actual
    };

    // GESTION DE RECLUTAMIENTO: REGISTRAR COMO EL DOCTOR CONOCIO DOCTORFY
    const [todosReclutadores, setTodosReclutadores] = useState([]); // lo traido del backend
    useEffect(() => {
        const traerReclutadores = async () => {
            const reclutadoresTraidas = await reclutadoresServices.getAll();
            setTodosReclutadores(reclutadoresTraidas);
        };
        traerReclutadores();
    }, []);


    // GESTION DEL REGISTRO DE UN PACIENTE: OBRAS SOCIALES, EMPRESAS PREPAGA
    const [todasEmpresasPrepaga, setTodasEmpresasPrepaga] = useState([]); // las traidas del backend
    const [todasObrasSociales, setTodasObrasSociales] = useState([]);
    const [OSElegida, setOSElegida] = useState(null); // las elegidas por el usuario
    const [EPElegida, setEPElegida] = useState(null)

    useEffect(() => { // efectos para traer los arrays del backend 
        const traerObrasSociales = async () => {
            const obrasSocialesTraidas = await obrasSocialesServices.getAll();
            setTodasObrasSociales(obrasSocialesTraidas);
        };
        traerObrasSociales();
    }, []);

    useEffect(() => {
        const traerEmpresasPrepaga = async () => {
            const empresasTraidas = await empresasPrepagaServices.getAll();
            setTodasEmpresasPrepaga(empresasTraidas);
        }
        traerEmpresasPrepaga();
    }, []);

    // estados que controlan si el usuario indico si tiene obra social o empresa prepaga o ambas, y ademas 
    // en funcion de esa informacion, mostrar los planes correspondientes que puede elegir
    const [obraSocialYPrepaga, setObraSocialYPrepaga] = useState('');
    const [planesPosibles, setPlanesPosibles] = useState([]);

    // cada vez que el usuario cambie de opcion en los selects de empresa o de obra social, se ejecuta este efecto
    // que lo que hace es que si el usuario marco que solo tiene empresa prepaga, setee obra social como null
    // y viceversa. Despues, en funcion de los valores de empresa y obra social, hace la llamada a la API
    // para traer los planes que correspondan a las opciones elegidas por el usuario
    useEffect(() => {
        const traerPlanesPosibles = async () => {
            // si el usuario marco solo empresaprepaga
            if (obraSocialYPrepaga === "1") {
                // handleRespuestaRegistro('IdObraSocial', null);
                setOSElegida(null)
            };

            // si el usuario marco solo obra social
            if (obraSocialYPrepaga === "2") {
                // handleRespuestaRegistro('IdEmpresaPrepaga', null);
                setEPElegida(null)
            }

            const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:4000'; // declaracion de la variable de entorno de la API
            // traer los planes posibles para lo que haya marcado el usuario
            const planesTraidos = await axios.get(`${baseURL}/api/plataforma_medica/numerosPlanes/deObraSocialYDeEmpresaEspecifica/${OSElegida}/${EPElegida}`);
            setPlanesPosibles(planesTraidos.data)
        };
        traerPlanesPosibles();
    }, [EPElegida, OSElegida]);

    // cada vez que el usuario elija una opcion distinta en el select de si tiene empresa prepaga u obra social, 
    // se resetean dichos valores por mas de que esté este bug de que se sigue apareciendo como marcado en el select
    useEffect(() => {
        if (esMedico === false) {
            setOSElegida(null)
            setEPElegida(null)
        }
    }, [obraSocialYPrepaga]);

    return {
        paises,
        estadosPaisEspecifico,
        ciudadesEstadoEspecifico,
        paisElegido,
        setPaisElegido,
        estadoElegido,
        setEstadoElegido,
        ciudadElegida,
        setCiudadElegida,
        fechaActualString,
        fechaMinimaString,
        handleTelefonoKeyPress,
        todasEspecialidadesMedicas, // especialidades medicas y funciones vinculadas
        especialidades,
        setEspecialidades,
        handleEspecialidadesChange,
        handleAddEspecialidad,
        handleRemoveEspecialidad,
        todosAtencionLugares, // lugares de atencion y funciones vinculadas
        atencionLugares,
        setAtencionLugares,
        handleAtencionLugaresChange,
        handleNombreChange,
        handleDireccionChange,
        handleCiudadChange,
        handleAddAtencionLugar,
        handleRemoveAtencionLugar,
        files, // archivos y funciones vinculadas
        setFiles,
        handleFileChange,
        handleRemoveFile,
        todosReclutadores,
        todasEmpresasPrepaga, // empresas prepaga, obras sociales, y funcionalidades vinculadas para el registro de pacientes
        todasObrasSociales,
        OSElegida,
        setOSElegida,
        EPElegida,
        setEPElegida,
        obraSocialYPrepaga,
        setObraSocialYPrepaga,
        planesPosibles,
        setPlanesPosibles
    }
}

export { useFormularioRegistro }