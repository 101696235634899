import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import "./BotonNotificaciones.css";

// llamar a esto en un futuro: BotonContacto
const BotonNotificaciones = ({ setEstaEnContacto }) => {
    const navigate = useNavigate();

    return (
        <a
            data-tooltip-id="my-tooltip-contacto"
            data-tooltip-content="Contacto"
            id="boton-notificaciones-cta"
        >
            <button onClick={() => { setEstaEnContacto(true); navigate("/contacto") }} className='btn waves-effect waves-light grey darken-2' id="boton-notificaciones-cta">
                <i className='medium material-icons text-darken-1' id="icono-notificaciones-cta">email</i>
            </button>
            <Tooltip
                id='my-tooltip-contacto'
            />
        </a>

    );
};

export { BotonNotificaciones }