import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useFormularioRegistro } from '../../hooks/customHooks/useFormularioRegistro'
import logoPTM from "../../../../images/newLogoPTM.jpeg"
import "./Formulario.css"

const Formulario = ({ esMedico, setEsMedico }) => {
  const navigate = useNavigate() // gestion de navegacion
  const { handleSubmit, register, formState: { errors }, reset } = useForm() // gestion de campos de formulario
  const [hizoClick, setHizoClick] = useState(false)

  // custom hook
  const {
    paises,
    estadosPaisEspecifico,
    ciudadesEstadoEspecifico,
    paisElegido,
    setPaisElegido,
    estadoElegido,
    setEstadoElegido,
    ciudadElegida,
    setCiudadElegida,
    fechaActualString,
    fechaMinimaString,
    handleTelefonoKeyPress,
    todasEspecialidadesMedicas,
    especialidades,
    setEspecialidades,
    handleEspecialidadesChange,
    handleAddEspecialidad,
    handleRemoveEspecialidad,
    todosAtencionLugares,
    atencionLugares,
    setAtencionLugares,
    handleAtencionLugaresChange,
    handleCiudadChange,
    handleDireccionChange,
    handleNombreChange,
    handleAddAtencionLugar,
    handleRemoveAtencionLugar,
    files,
    setFiles,
    handleFileChange,
    handleRemoveFile,
    todosReclutadores,
    todasEmpresasPrepaga,
    todasObrasSociales,
    OSElegida,
    setOSElegida,
    EPElegida,
    setEPElegida,
    obraSocialYPrepaga,
    setObraSocialYPrepaga,
    planesPosibles,
    setPlanesPosibles
  } = useFormularioRegistro(esMedico)

  // estados de ERROR de registro de formulario
  const [errorResidencia, setErrorResidencia] = useState(false)
  const [passwordsCoinciden, setPasswordsCoinciden] = useState(true)
  const [errorEspecialidades, setErrorEspecialidades] = useState(false)
  const [errorLAs, setErrorLAs] = useState(false)
  const [errorOSEP, setErrorOSEP] = useState(false)
  const [responseError, setResponseError] = useState(""); // si hay algun error devuelto por el backend a la hora de registrarse, por ejemplo, violaciones de unicidad

  const resetearEstados = () => {
    setErrorResidencia(false)
    setPasswordsCoinciden(true)
    setErrorEspecialidades(false)
    setErrorLAs(false)
    setErrorOSEP(false)
    setResponseError("")
    setPaisElegido(0)
    setEstadoElegido(0)
    setCiudadElegida(0)
    setEspecialidades([0])
    setAtencionLugares([0])
    setFiles(null)
    setOSElegida(null)
    setEPElegida(null)
    setObraSocialYPrepaga("")
    setPlanesPosibles([])
    setHizoClick(false)
    reset()
  }

  const validateAtencionLugares = (atencionLugares) => {
    // Si el array tiene solo un elemento y cumple con las condiciones específicas, se considera válido
    if (atencionLugares.length === 1 &&
      atencionLugares[0].id === 0 &&
      atencionLugares[0].nombre === '' &&
      atencionLugares[0].direccion === '' &&
      (atencionLugares[0].ciudad === '' || atencionLugares[0].ciudad === 0)
    ) {
      return false;
    }

    // Realizar la validación si hay más de un elemento en el array
    return atencionLugares.some(element => (
      element.id === 0 ||
      element.nombre === '' ||
      element.direccion === '' ||
      element.ciudad === '' ||
      element.ciudad === 0
    ));
  };

  // funcion VOLVER
  const handleBack = () => {
    resetearEstados()
    setEsMedico(null)
  }

  // funcion REGISTRARSE
  const onSubmit = async (data) => {
    setHizoClick(true)
    data.IdCiudad = ciudadElegida // obtengo la ciudad

    // VALIDACIONES PARA TODOS LOS TIPOS DE USUARIO POSIBLES
    if (data.IdCiudad === 0) { // si no ingreso la ciudad, tira error
      setErrorResidencia(true)
      setHizoClick(false)
      return
    }
    setErrorResidencia(false)

    if (data.Contrasena !== data.RepContrasena) { // si las contraseñas no son iguales, tira error
      setPasswordsCoinciden(false)
      return
    }
    setPasswordsCoinciden(true)

    // VALIDACIONES UNICAMENTE PARA LOS PROFESIONALES
    if (esMedico === true) {
      data.Rec_id = parseInt(data.Rec_id)
      data.Archivos = files
      data.Especialidades = especialidades
      data.AtencionLugares = atencionLugares
      if (
        data.Especialidades.includes(0) ||
        new Set(data.Especialidades).size !== data.Especialidades.length ||
        data.Especialidades.length === 0
      ) {
        setErrorEspecialidades(true)
        setHizoClick(false)
        return
      }
      setErrorEspecialidades(false)

      if (validateAtencionLugares(data.AtencionLugares)) {
        setErrorLAs(true);
        setHizoClick(false)
        return;
      }
      setErrorLAs(false);

      data.AtencionLugares = (data.AtencionLugares.length === 1 && data.AtencionLugares[0].id === 0) ? [] : data.AtencionLugares;
      data.AtencionLugares.forEach(element => delete element.id);
    }

    // VALIDACIONES UNICAMENTE PARA LOS PACIENTES
    if (esMedico === false) {
      // si eligio que tiene obraSocial, empresa, o ambas, pero en algun select no completo:
      if (obraSocialYPrepaga === '1' && (EPElegida === null || data.IdNumeroPlan === undefined || data.IdNumeroPlan === null)) {
        setErrorOSEP(true);
        return
      };

      if (obraSocialYPrepaga === '2' && (OSElegida === null || data.IdNumeroPlan === undefined || data.IdNumeroPlan === null)) {
        setErrorOSEP(true);
        return
      };

      if (obraSocialYPrepaga === '3' && (EPElegida === null || OSElegida === null || data.IdNumeroPlan === undefined || data.IdNumeroPlan === null)) {
        setErrorOSEP(true);
        return
      };
      setErrorOSEP(false)
      data.IdObraSocial = OSElegida
      data.IdEmpresaPrepaga = EPElegida
      data.IdNumeroPlan = (data.IdNumeroPlan !== undefined) ? parseInt(data.IdNumeroPlan) : null
      data.NumeroAfiliado = data.NumeroAfiliado !== undefined ? data.NumeroAfiliado : null
    }

    const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:4000'; // declaracion de la variable de entorno de la API
    let url
    if (esMedico === true) {
      url = baseURL + '/api/plataforma_medica/profesionales'
      const fechaActual = new Date();
      const fechaFormateada = `${fechaActual.getFullYear()}-${(fechaActual.getMonth() + 1).toString().padStart(2, '0')}-${fechaActual.getDate().toString().padStart(2, '0')}`;
      data.Fecha_reg = fechaFormateada;
    } else if (esMedico === false) {
      url = baseURL + '/api/plataforma_medica/usuarios'
    } else {
      url = baseURL + '/api/plataforma_medica/usuarios'
      console.error('Tipo de usuario inválido');
    };
    const response = await axios.post(url, data)

    // si hubo un error al registrarse, que permita volver a hacer click en registrarse, corrigiendo lo que corresponda
    if (response.data.error) {
      setHizoClick(false);
      setResponseError(response.data.error)
      return;
    }
    setResponseError("") // reseteando el estado de error si no hubo errores al registrarse
    resetearEstados()
    setEsMedico(null)
    navigate("/confRegistro")
  }

  return (
    <>
      <div className='row'>
        <div className="logo-container center">
          <img src={logoPTM} alt="Logo PTM" width="500" />
        </div>
        <div className='col s12 center'>
          <h5 id="header-form-registro" className='center-align'>{esMedico === true ? "REGISTRO DE PROFESIONAL DE LA SALUD" : "REGISTRO DE USUARIOS"}</h5>
        </div>

        {esMedico === true ?
          <div className='col s12 center'>
            <a href='https://www.doctorfy.com.ar/'>
              <button
                className='btn waves-effect waves-light'
                type="button"
                onClick={handleBack}
                style={{ marginBottom: "12px" }}
              >
                VOLVER
              </button>
            </a>

          </div> :
          <div className='col s12 center'>
            <a href='https://www.doctorfy.com.ar/'>
              <button
                className='btn waves-effect waves-light'
                type="button"
                onClick={handleBack}
                style={{ marginBottom: "12px", backgroundColor: 'grey' }}
              >
                CANCELAR
              </button>
            </a>
          </div>
        }

        <div className="col s6 l4">
          <label htmlFor="nombre" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Ingresá tu Nombre</label>
          <input
            id="nombre"
            name="nombre"
            type="text"
            placeholder='ej: Juan'
            style={{ fontWeight: "bold", marginBottom: "4px" }}
            {...register("Nombre", {
              required: {
                value: true,
                message: "El nombre es requerido."
              },
              maxLength: {
                value: 30,
                message: "El nombre no puede tener más de 30 caracteres"
              }
            })}
          />
          {errors.Nombre && <span className='error-message-2'>{errors.Nombre.message}</span>}
        </div>


        <div className="col s6 l4">
          <label htmlFor="apellido" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Ingresá tu Apellido</label>
          <input
            id="apellido"
            name="apellido"
            type="text"
            placeholder='ej: Aguirre'
            style={{ fontWeight: "bold", marginBottom: "4px" }}
            {...register("Apellido", {
              required: {
                value: true,
                message: "El apellido es requerido."
              },
              maxLength: {
                value: 30,
                message: "El apellido no puede tener más de 30 caracteres"
              }
            })}
          />
          {errors.Apellido && <span className='error-message-2'>{errors.Apellido.message}</span>}
        </div>


        <div className="col s12 m6 l4">
          <label htmlFor="email" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Ingresá tu Correo Electrónico</label>
          <input
            id="email"
            type="text"
            name="email"
            placeholder='xxxxxx@xxxxxxx.xxx'
            style={{ fontWeight: "bold", marginBottom: "4px" }}
            {...register("Correo", {
              required: {
                value: true,
                message: "El correo electrónico es requerido."
              },
              pattern: {
                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                message: "El correo electronico debe tener este formato: xxxxx@xxxxx.xxx"
              }
            })}
          />
          {errors.Correo && <span className='error-message-2'>{errors.Correo.message}</span>}
        </div>


        <div className="col s6 l4">
          <label htmlFor="pais" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>País de Residencia</label>
          <select
            className="browser-default validate"
            id="pais"
            name="pais"
            style={{ fontWeight: "bold" }}
            onChange={(e) => setPaisElegido(parseInt(e.target.value))}
            defaultValue={0}
          >
            <option value={0} disabled>Elegí una opcion</option>
            {paises.map((e, index) => (
              <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
            ))}
          </select>
        </div>

        {paisElegido !== 0 && (
          <div className="col s6 l4">
            <label htmlFor="provincia" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Provincia de Residencia</label>
            <select
              className="browser-default validate"
              id="provincia"
              name="provincia"
              style={{ fontWeight: "bold" }}
              onChange={(e) => setEstadoElegido(parseInt(e.target.value))}
              defaultValue={0}
            >
              <option value={0} disabled>Elegí una opcion</option>
              {estadosPaisEspecifico.map((e, index) => (
                <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
              ))}
            </select>
          </div>
        )}

        {estadoElegido !== 0 && (
          <div className="col s6 l4">
            <label htmlFor="cuidad" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Ciudad de Residencia</label>
            <select
              className="browser-default validate"
              id="ciudad"
              style={{ fontWeight: "bold" }}
              name="ciudad"
              required
              defaultValue={0}
              onChange={(e) => { setCiudadElegida(parseInt(e.target.value)) }} >
              <option value={0} disabled>Elegí una opcion</option>
              {ciudadesEstadoEspecifico.map((e, index) => (
                <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
              ))}
            </select>
          </div>
        )}

        {errorResidencia && <div className='col s12 center'> <span className='error-message-2'>ERROR. Por favor completá toda la informacion sobre tu residencia</span></div>}

        {esMedico === false && (
          <>
            <div className="col s6 l4">
              <label htmlFor="sexo" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Sexo</label>
              <select
                className="browser-default validate"
                id="sexo"
                name="sexo"
                required
                style={{ fontWeight: "bold" }}
                defaultValue=""
                {...register("Sexo", {
                  required: {
                    value: true,
                    message: "ERROR. Por favor indicá tu sexo."
                  }
                })}
              >
                <option value="" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                <option value="Masculino" style={{ fontWeight: "bold" }}>Masculino</option>
                <option value="Femenino" style={{ fontWeight: "bold" }}>Femenino</option>

              </select>
              {errors.Sexo && <span className='error-message-2'>{errors.Sexo.message}</span>}
            </div>


            <div className='col s6 l4' style={{ marginBottom: "4px" }}>
              <label htmlFor="fechaNacimiento" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Fecha de Nacimiento</label>
              <input
                type="date"
                className="datepicker validate"
                id="fechaNacimiento"
                name="fechaNacimiento"
                min={fechaMinimaString}
                max={fechaActualString}
                style={{ fontWeight: "bold", marginBottom: "0px" }}
                {...register("FechaDeNacimiento", {
                  required: {
                    value: true,
                    message: "Ingresá tu fecha de nacimiento completa"
                  }
                })}
              />
              {errors.FechaDeNacimiento && <span className='error-message-2'>{errors.FechaDeNacimiento.message}</span>}
            </div>
          </>
        )}

        <div className='col s12' style={{ marginBottom: "3px" }}>
          <label htmlFor='domicilio' style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Ingresá tu Domicilio</label>
          <input
            type="text"
            id="domicilio"
            name="domicilio"
            placeholder='ej: Av. Rivadavia 1234'
            className='materialize-textarea validate'
            style={{ fontWeight: "bold", marginBottom: "0px" }}
            {...register("Domicilio", {
              required: {
                value: true,
                message: "ERROR. Por favor, ingresá tu domicilio"
              },
              maxLength: {
                value: 100,
                message: "El domicilio no puede tener mas de 100 caracteres"
              }
            })}
          />
          {errors.Domicilio && <span className='error-message-2'>{errors.Domicilio.message}</span>}
        </div>


        <div className="col s6 l4">
          <label htmlFor="numeroTelefonico" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Ingresá tu Numero de Telefono (código de area + celular)</label>
          <input
            type="text"
            id="numeroTelefonico"
            name="numeroTelefonico"
            placeholder='ej: 1122222222'
            className='validate'
            style={{ fontWeight: "bold", marginBottom: "0px" }}
            onKeyPress={handleTelefonoKeyPress}
            {...register("NumeroTelefono", {
              required: {
                value: true,
                message: "ERROR. Por favor, ingresá tu número de teléfono"
              },
              minLength: {
                value: 9,
                message: "El número de teléfono debe tener al menos 9 caracteres"
              },
              maxLength: {
                value: 13,
                message: "El número de teléfono debe tener no más de 13 caracteres"
              }
            })}
          />
          {errors.NumeroTelefono && <span className='error-message-2'>{errors.NumeroTelefono.message}</span>}
        </div>


        <div className="col s6 l4">
          <label htmlFor="contrasena" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Create tu Contraseña "Máximo 15 caracteres" y "Minimo 4 caracteres"</label>
          <input
            type="password"
            id="constrasena"
            name="contrasena"
            placeholder='********'
            style={{ fontWeight: "bold", marginBottom: "0px" }}
            {...register("Contrasena", {
              required: {
                value: true,
                message: "ERROR. Por favor, create una contraseña"
              },
              minLength: {
                value: 4,
                message: "La contraseña debe tener como mínimo 4 caracteres"
              },
              maxLength: {
                value: 15,
                message: "La contraseña debe tener como máximo 15 caracteres"
              }
            })}
          />
          {errors.Contrasena && <span className='error-message-2'>{errors.Contrasena.message}</span>}
        </div>


        <div className="col s6 l4">
          <label htmlFor="confirmacionContrasena" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Repetí la Contraseña (debe ser igual a la que escribiste recién)</label>
          <input
            type="password"
            id="confirmacionContrasena"
            name="confirmacionContrasena"
            placeholder='********'
            style={{ fontWeight: "bold", marginBottom: "0px" }}
            {...register("RepContrasena", {
              required: {
                value: true,
                message: "ERROR. Por favor, repetí la contraseña"
              },
              minLength: {
                value: 4,
                message: "La contraseña debe tener como mínimo 4 caracteres"
              },
              maxLength: {
                value: 15,
                message: "La contraseña debe tener como máximo 15 caracteres"
              }
            })}
          />
          {errors.RepContrasena && <span className='error-message-2'>{errors.RepContrasena.message}</span>}
          {!passwordsCoinciden && <span className='error-message-2'>ERROR. LAS CONTRASEÑAS DEBEN SER IGUALES</span>}
        </div>

        {esMedico === true && (
          <>
            <div className='col s6 l4'>
              <label htmlFor="nroMatricula" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Ingresá tu Número de matrícula (5 o 6 caracteres)</label>
              <input
                type="text"
                id="nroMatricula"
                name="nroMatricula"
                style={{ fontWeight: "bold", marginBottom: "0px" }}
                {...register("NumeroMatricula", {
                  required: {
                    value: true,
                    message: "ERROR. Por favor, ingresá tu número de matrícula"
                  },
                  minLength: {
                    value: 5,
                    message: "El número de matrícula debe tener al menos 5 dígitos"
                  },
                  maxLength: {
                    value: 6,
                    message: "El número de matrícula debe tener máximo 6 dígitos"
                  },
                  pattern: {
                    value: /^\+?\d+$/,
                    message: "El número de matrícula no puede contener letras ni símbolos especiales"
                  }
                })}
              />
              {errors.NumeroMatricula && <span className='error-message-2'>{errors.NumeroMatricula.message}</span>}
            </div>


            <div className='col s12'>
              <label htmlFor="resCurricular" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Resumen Curricular</label>
              <textarea
                type="text"
                id="resCurricular"
                name="resCurricular"
                style={{ fontWeight: "bold", marginBottom: "0px" }}
                {...register("ResumenCurricular", {
                  required: {
                    value: true,
                    message: "Ingresá un breve resumen curricular."
                  }
                })}
              />
              {errors.ResumenCurricular && <span className='error-message-2'>{errors.ResumenCurricular.message}</span>}
            </div>


            {/* REGISTRAR UNA O MAS ESPECIALIDADES  */}
            {especialidades.map((especialidad, index) => {
              return (
                <div key={index} className='col s6 l4'>
                  <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>
                    Especialidad {index + 1}:
                  </label>
                  <select
                    name="especialidad"
                    defaultValue="0"
                    onChange={(e) => handleEspecialidadesChange(e, index)}
                    className='browser-default'
                    style={{ fontWeight: "bold" }}
                    required
                  >
                    <option value="0" disabled style={{ fontWeight: "bold" }}>Seleccione una especialidad</option>
                    {todasEspecialidadesMedicas.map((e, index) => {
                      return (
                        <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                      )
                    })}
                  </select>
                </div>
              )
            })}

            <div className='col s12 center'>
              <button type="button" onClick={handleAddEspecialidad} id="addEspecialidad" className='btn waves-effect waves-light'>
                Agregar Especialidad
              </button>
            </div>

            {/* {id = "addEspecialidad"} */}

            {especialidades.length > 1 && (
              <div className="col s12 center">
                <button type="button" onClick={() => handleRemoveEspecialidad(especialidades.length - 1)} id="removeEspecialidad" className='btn waves-effect waves-light'>
                  Eliminar Especialidad
                </button>
              </div>
            )}

            {errorEspecialidades && (
              <div className='col s12 center'>
                <p className='error-message-2'>ERROR. No dejes especialidades sin elegir</p>
              </div>
            )}

            {/* REGISTRAR 0 O MUCHOS LUGARES DE ATENCION */}
            {/* {atencionLugares.map((atencionLugar, index) => {
              return (
                <div key={index} className='col s12'>
                  <label>
                    Lugar de Atención {index + 1}:
                  </label>
                  <select
                    name="atencionLugar"
                    defaultValue="0"
                    onChange={(e) => handleAtencionLugaresChange(e, index)}
                    className='browser-default'
                    required
                  >
                    <option value="0" disabled>Seleccione un lugar de atención</option>
                    {todosAtencionLugares.map((e, index) => {
                      return (
                        <option key={index} value={e.Id}>{e.Nombre}</option>
                      )
                    })}
                  </select>
                  {atencionLugar.id !== 0 && (
                    <div>
                      <input type="text" name={`nombre_${index}`} placeholder="Nombre" value={atencionLugar.nombre} onChange={(e) => handleNombreChange(e, index)} />
                      <input type="text" name={`direccion_${index}`} placeholder="Dirección" value={atencionLugar.direccion} onChange={(e) => handleDireccionChange(e, index)} />
                      <select name={`ciudad_${index}`} value={atencionLugar.ciudad} onChange={(e) => handleCiudadChange(e, index)} className='browser-default'>
                        <option value="">Seleccione una ciudad</option>
                        {ciudadesEstadoEspecifico.map((e, index) => (
                          <option key={index} value={e.Id}>{e.Nombre}</option>
                        ))}
                      </select>
                    </div>
                  )}
                </div>
              )
            })}

            {atencionLugares.length > 1 && (
              <div className="col s12 center">
                <button type="button" onClick={() => handleRemoveAtencionLugar(atencionLugares.length - 1)} className='btn waves-effect waves-light'>
                  Eliminar Lugar de Atención
                </button>
              </div>
            )}

            <div className='col s12 center'>
              <button type="button" onClick={handleAddAtencionLugar} id="addLA" className='btn waves-effect waves-light'>
                Agregar Lugar de Atención
              </button>
            </div>

            {errorLAs && (
              <p className='error-message-2'>ERROR. Completá toda la información referida a los lugares de atención</p>
            )} */}

            <div className='col s12'></div>
            {/* <div className="file-field col s12">
              <div className="btn">
                <span>Subir Documento</span>
                <input type="file" onChange={handleFileChange} multiple />
              </div>
              <div className="file-path-wrapper">
                <input className="file-path validate" type="text" placeholder="Selecciona archivos" />
              </div>
            </div>

            {files && (
              <div className="col s12" style={{ marginBottom: '12px' }}>
                <div className="col s6 left">
                  <p style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>{files.name}</p>
                </div>
                <div className="col s6 right" style={{ marginTop: '10px' }}>
                  <button className="btn red" onClick={handleRemoveFile}>Eliminar</button>
                </div>
              </div>
            )} */}


            <div className="col s12">
              <label htmlFor="rec" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>¿Quién te puso en contacto con DOCTORFY?</label>
              <select
                className="browser-default validate"
                id="rec"
                name="rec"
                defaultValue=""
                style={{ fontWeight: "bold", marginBottom: "0px" }}
                {...register("Rec_id", {
                  required: {
                    value: true,
                    message: "ERROR. Por favor, indicá quién te puso en contacto con DOCTORFY"
                  }
                })}
              >
                <option value="" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                {todosReclutadores.map((e, index) => {
                  return (
                    <option key={index} value={e.IdReclutador} style={{ fontWeight: "bold" }}>{e.Nombre + ' ' + e.Apellido}</option>
                  )
                })}
              </select>
              {errors.Rec_id && <p className='error-message-2'>{errors.Rec_id.message}</p>}
            </div>
          </>
        )}

        {/* {esMedico === false && (
          <>
            <div className='col s12'>
              <label htmlFor="eleccionEPuOS" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Tenés Obra Social o pagás alguna mensualidad a una empresa de medicina prepaga? "Si no tenes ninguna, no elijas"</label>
              <select style={{ fontWeight: "bold" }} className="browser-default validate" id="eleccionEPuOS" name="eleccionEPuOS" defaultValue="0" onChange={(e) => setObraSocialYPrepaga(e.target.value)}>
                <option value="0" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                <option value="1" style={{ fontWeight: "bold" }}>Tengo Empresa Prepaga</option>
                <option value="2" style={{ fontWeight: "bold" }}>Tengo Obra Social</option>
                <option value="3" style={{ fontWeight: "bold" }}>Tengo Ambas</option>
              </select>
            </div>

            {(obraSocialYPrepaga === "1" || obraSocialYPrepaga === "3") && (
              <div className="col s6 l4">
                <label htmlFor="empresaPrepaga" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Empresa Prepaga</label>
                <select
                  className="browser-default validate"
                  id="empresaPrepaga"
                  style={{ fontWeight: "bold" }}
                  name="empresaPrepaga"
                  defaultValue="0"
                  onChange={(e) => setEPElegida(parseInt(e.target.value))}
                  required
                >
                  <option value="0" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                  {todasEmpresasPrepaga.map((e, index) => {
                    return (
                      <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                    )
                  })}
                </select>
              </div>
            )}

            {(obraSocialYPrepaga === "2" || obraSocialYPrepaga === "3") && (
              <div className="col s6 l4">
                <label htmlFor="obraSocial" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Obra Social</label>
                <select
                  className="browser-default validate custom-select"
                  id="obraSocial"
                  name="obraSocial"
                  style={{ fontWeight: "bold" }}
                  defaultValue="0"
                  // onChange={(e) => handleRespuestaRegistro('IdObraSocial', parseInt(e.target.value))} 
                  onChange={(e) => setOSElegida(parseInt(e.target.value))}
                  required
                >
                  <option value="0" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                  {todasObrasSociales.map((e, index) => {
                    return (
                      <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                    );
                  })}
                </select>
              </div>
            )}

            {(obraSocialYPrepaga !== "" && planesPosibles.length > 0) && (
              <>
                <div className="col s6 l4">
                  <label htmlFor="nroPlan" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Numero de Plan</label>
                  <select
                    className="browser-default validate custom-select"
                    id="nroPlan"
                    name="nroPlan"
                    defaultValue=""
                    style={{ fontWeight: "bold", marginBottom: "0px" }}
                    // onChange={(e) => handleRespuestaRegistro('IdNumeroPlan', parseInt(e.target.value))} 
                    // required
                    {...register("IdNumeroPlan", {
                      required: {
                        value: true,
                        message: "ERROR. Porfavor, Elegí el plan que tengas"
                      }
                    })}
                  >
                    <option value="" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                    {planesPosibles.map((e, index) => {
                      return (
                        <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                      );
                    })}
                  </select>
                  {errors.IdNumeroPlan && <span className='error-message-2'>{errors.IdNumeroPlan.message}</span>}
                </div>


                <div className="col s6 l4" style={{ marginBottom: "16px" }}>
                  <label htmlFor="numeroAfiliado" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Número de Afiliado</label>
                  <input
                    id="numeroAfiliado"
                    name="numeroAfiliado"
                    type="text"
                    style={{ fontWeight: "bold", marginBottom: "0px" }}
                    {...register("NumeroAfiliado", {
                      required: {
                        value: true,
                        message: "ERROR. Porfavor indicá tu número de afiliado"
                      },
                      maxLength: {
                        value: 30,
                        message: "El número de afiliado no puede tener más de 30 caracteres"
                      }
                    })}
                  />
                  {errors.NumeroAfiliado && <span className='error-message-2'>{errors.NumeroAfiliado.message}</span>}
                  {errorOSEP && <span className='error-message-2'>ERROR. Por favor completá toda la informacion sobre tus coberturas médicas</span>}
                </div>
              </>
            )}
          </>
        )} */}

        {responseError && <span className='error-message-2'>ERROR. Ya existe una cuenta con estos datos, por favor, revisá que hayas ingresado todo correctamente.</span>}

        <div className="col s12 center">
          <button
            className='btn waves-effect waves-light'
            id="registrarUsuario"
            type="submit"
            disabled={hizoClick}
            onClick={handleSubmit(onSubmit)}
            style={{ marginTop: "12px" }}
          >
            REGISTRARSE
            <i className='material-icons right large'>account_box</i>
          </button>
        </div>
      </div>
    </>
  )
}

export { Formulario }