import axios from "axios"
const apiUrl2 = process.env.REACT_APP_API_URL_2 || 'http://localhost:4000'; // declaracion de variable de entorno de la API del BO

// data: {username: "", papsword: ""}
const login = async (data) => {
    try {
        const turnosTraidos = await axios.post(`${apiUrl2}/api/pm/bo/login/`, data);
        return turnosTraidos.data;
    } catch (error) {
        if (error.response) {
            return error.response.data
        }
    }
}

const loginBOServices = {
    login,
}

export { loginBOServices }