import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFecha } from '../../hooks/useFecha.js';
import axios from 'axios';
import logoPTM from "../../../../images/newLogoPTM.jpeg";
import "./ConfirmacionTurnoPaciente.css";
import { useUserContext } from '../../../../useContexto.js';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '../../../components/Modal/Modal.js';
import { solicTurnoProfServices } from '../../../SolicitudTurno/SolicitudTurnoMedico/services/solicTurnoProf.service.js';
import { SessionExpired } from '../../../components/SessionExpired/SessionExpired.js';
import useIp from '../../../PantallaInicial/components/IniciarSesion/hooks/useIp.js';
import { useEvento } from '../../../../hooks/useEvento.js';


const ConfirmacionTurnoPaciente = ({
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
    showModal,
    handleOpenModal,
    handleCloseModal
}) => {
    const { register, handleSubmit, reset } = useForm();
    const { fechaString, hora } = useFecha();
    const navigate = useNavigate();

    // estados referidos al parametro id del turno generado por el link (aplica para AsignacionTurno, OfertaTurno, y ambas pantallas de confirmacion de turno)
    const [turnoSolicitado, setTurnoSolicitado] = useState({})
    const { id } = useParams();
    const [confrimoTurno, setConfirmoTurno] = useState(false)
    const [tokenVencido, setTokenVencido] = useState(false)

    const ip = useIp()
    const { handleCampoLlego, campoLlego, crearE } = useEvento()

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerElTurno = async () => {
        if (window.location.pathname.includes('confirmacionTurnoPac')) {
            if (usuarioEnSesion) {
                const resultado = await solicTurnoProfServices.getTurnoById(id, token, 4)
                if (resultado.error !== undefined) {
                    if (resultado.error === "TOKEN VENCIDO.") {
                        setTokenVencido(true);
                        return;
                    }
                    if (resultado.esCero) {
                        navigate("*")
                    }
                }
                setTurnoSolicitado(resultado)
            }
        }
    }

    // si esta en la pantalla de confrimacion de turno (lado paciente),  seteando los datos como lo traido del backend
    useEffect(() => {
        traerElTurno()
    }, [usuarioEnSesion])


    // funcion que enviara al backend el nuevo estado del turno
    const onSubmit = async (data) => {
        data.IdEstadoTurno = 6; // estado CONFIRMADO POR PACIENTE
        const response = await solicTurnoProfServices.updateTurno(turnoSolicitado.IdTurno, data, token, 5)
        if (response.error === "TOKEN VENCIDO.") {
            setTokenVencido(true);
            return;
        }
        setConfirmoTurno(true)
        reset();
        handleOpenModal()
    };

    const handleBack = () => {
        navigate('/misTurnosPac')
        crearE({ id_tipo: 82, id_resultado: 1, ip_user: ip, id_usuario: usuarioEnSesion?.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion?.usuarioComun ? usuarioEnSesion.Id : null }, token)
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="form-registro-container row">
                        <form className="col s12" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className='col s12 center'>
                                    <div className="logo-container center">
                                        <img src={logoPTM} alt="Logo PTM" width="500" />
                                    </div>
                                    <h5 id="header-form-registro">CONFIRMACION DE TURNO (PACIENTE)</h5>
                                </div>

                                <div className='col s12 center'>
                                    <button
                                        className='btn waves-effect waves-light'
                                        type="button"
                                        onClick={handleBack}
                                        style={{ marginBottom: '24px' }}
                                    >
                                        VOLVER
                                    </button>
                                </div>

                                {(turnoSolicitado?.Usuario !== undefined) && (
                                    <>
                                        <p className='info-oferta'>Fecha: {fechaString}. HORA: {hora}</p>
                                        <p className='info-oferta'>Señor(a): {turnoSolicitado?.Usuario?.Nombre}  {turnoSolicitado?.Usuario?.Apellido}</p>
                                        <p className='info-oferta'>Le recordamos su turno tomado bajo la modalidad {turnoSolicitado?.Modalidad} para el dia {turnoSolicitado?.Fecha}, a las {turnoSolicitado?.Hora}</p>
                                        <p className='info-oferta'>Con el/la Profesional: {turnoSolicitado?.Profesionale?.Nombre}  {turnoSolicitado?.Profesionale?.Apellido} cuyo número de matrícula es: {turnoSolicitado?.Profesionale?.NumeroMatricula}</p>
                                        <p className='info-oferta'>Especialista en:</p>
                                        <ul style={{ margin: 0 }}>
                                            {turnoSolicitado?.Profesionale?.Especialidades?.map((e, index) => (
                                                <li key={index} className='info-oferta'>{e.Nombre}</li>
                                            ))}
                                        </ul>
                                        <p></p>
                                        {turnoSolicitado?.Modalidad === "Presencial" && (
                                            <>
                                                <p className='info-oferta'>Lugar de atencion: {turnoSolicitado?.AtencionLugare?.Nombre}</p>
                                                <p className='info-oferta'>Dirección: {turnoSolicitado?.AtencionLugare?.Direccion}</p>
                                            </>
                                        )}
                                        <div className='col s12 center'>
                                            <button type="submit" className='btn waves-effect waves-light' style={{ marginBottom: "80px" }} disabled={confrimoTurno}>
                                                ACEPTAR
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </form>
                        {showModal && (
                            <Modal
                                title="CONFIRMASTE EL TURNO"
                                description="Tu confirmacion de turno ya le llegó al profesional :)"
                                onClose={() => { handleCloseModal(); crearE({ id_tipo: 84, id_resultado: 1, ip_user: ip, id_usuario: usuarioEnSesion?.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion?.usuarioComun ? usuarioEnSesion.Id : null }, token); navigate('/misTurnosPac') }}
                            />
                        )}
                    </div>
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    );
};

export { ConfirmacionTurnoPaciente }