// este contexto fue creado para poder almacenar el id del turno que se incluye en el link generado, 
// almacenarlo en forma global en toda la aplicacion, para que muchos componentes puedan utilizarlo a la vez.
import { createContext, useContext, useState } from 'react';
const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({ children }) => {
    const [idTurno, setIdTurno] = useState(0); // en este estado almaceno el id del turno, que viene como parametro en la URL generada desde el backend

    return (
        <UserContext.Provider value={{ idTurno, setIdTurno }}>
            {children}
        </UserContext.Provider>
    );
};

// ESTE CONTEXTO DE ALMACENAR EL LINK, NO LO VOY A NECESITAR MAS PORQUE TODO EL TEMA DEL ID SE VA A MANEJAR CON LA GRILLITA EN EL FRONTEND