import React, { useState } from 'react'
import logoPTM from "../../images/newLogoPTM.jpeg"
import { useNavigate } from "react-router-dom"
import { useForm } from 'react-hook-form'
import { loginBOServices } from './services/loginBO.service'

const Login = ({
    setEstaEnSesion,
    setUsuarioEnSesion,
    apiUrl,
    setToken
}) => {
    const navigate = useNavigate()
    const { register, formState: { errors }, handleSubmit, reset } = useForm()
    const [noExisteUsuario, setNoExisteUsuario] = useState(false); // validacion de si el usuario existe o no

    const onSubmit = async (data) => {

        const userOnlineData = await loginBOServices.login(data)

        if (userOnlineData.error && userOnlineData.error === 'No existe usuario') {
            setNoExisteUsuario(true)
            return
        }
        setEstaEnSesion(true);
        setUsuarioEnSesion(userOnlineData);
        window.localStorage.setItem("DoctorFy", JSON.stringify(userOnlineData)); // guardo el usuario en localStorage para persistencia de sesion
        setToken(userOnlineData.token);

        // navegar hacia la pantalla de bienvenida que corresponda y resetear lo ingresado en el form de inicio de sesion
        navigate('/bienvenida/BO')
        reset();
    }

    const handleBack = () => {
        setEstaEnSesion(false);
        setUsuarioEnSesion(null);
        navigate("/");
    }


    return (
        <>
            <div className="img-container">
                <img src={logoPTM} alt="Logo Alimenta Salud" />
            </div>
            <div className='container-center'>
                <div className="row">
                    <div className='col s12 center'>
                        <h3 className='header-iniciar-sesion light-blue accent-2 blue-text text-darken-4' style={{ fontSize: "25px" }}>INICIAR SESION BACKOFFICE</h3>
                    </div>
                    <form className="col s12" onSubmit={handleSubmit(onSubmit)}>
                        <div className="col s12">
                            <label htmlFor="emailInicioSesion">Usuario</label>
                            <input id="emailInicioSesion" type="text" className="validate" {...register('username', { required: true })} />
                        </div>
                        {errors.username && <span className='error-message'>ERROR. El usuario es requerido <br></br></span>}

                        <div className="col s12">
                            <label htmlFor="passwordInicioSesion">Contraseña</label>
                            <input id="passwordInicioSesion" type="password" className="validate" {...register('password', { required: true, minLength: 4, maxLength: 15 })} />
                        </div>
                        {errors.password && <span className='error-message'>ERROR. Por favor ingresá una contraseña válida</span>}
                        {noExisteUsuario && <span className='error-message'>ERROR. Mail o contraseña incorrectos, intentá de nuevo</span>}

                        <div className='col s12 center'>
                            <button
                                className="btn waves-effect waves-light light-green darken-2 btn-inicio-sesion"
                                type="submit"
                                name="action"
                            >
                                INICIAR SESION
                                <i className="material-icons right">send</i>
                            </button>
                        </div>

                        <div className='col s12 center'>
                            <button
                                className='btn waves-effect waves-light'
                                type="button"
                                onClick={handleBack}
                                style={{ marginTop: "20px", marginBottom: "100px" }}
                            >
                                VOLVER
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export { Login }