import React from 'react';
import './Modal.css'; // Archivo CSS para estilizar el modal

const Modal = ({ title, description, onClose }) => {
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>
                    <i
                        className='material-icons'
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        id="modal-close-icon"
                    >
                        close
                    </i>
                </button>
                <div className='col s12 center'>
                    <h4 id="header-modal">{title}</h4>
                </div>
                <p id="description-modal">{description}</p>
                <div className='col s12 center'>
                    <button onClick={onClose} className='btn waves-effect waves-light green darken-3' id="modal-button">
                        OK
                        <i className='material-icons right large'>done</i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export { Modal };
