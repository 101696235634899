// servicio que trae del backend todas las obras sociales existentes en la base de datos
import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000';  // declaracion de variable de entorno de la API

const getAll = async () => {
    const obrasSocialesTraidas = await axios.get(`${apiUrl}/api/plataforma_medica/obrasSociales`);
    const obrasSociales = obrasSocialesTraidas.data;
    return obrasSociales;
};

const obrasSocialesServices = {
    getAll
};

export { obrasSocialesServices }