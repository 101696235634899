import React, { useEffect, useState } from 'react'; // importando libreria de REACT
import './Bienvenida.css'; // importando estilos
import { useNavigate } from 'react-router-dom'; // importnado funcionalidad de navegacion entre componentes
import logoPTM from '../../images/newLogoPTM.jpeg'; // importando logo de la aplicacion
import axios from 'axios';
import { salaEsperaPacServices } from './services/salaEsperaPac.service';
import { SessionExpired } from '../components/SessionExpired/SessionExpired';
import useIp from '../PantallaInicial/components/IniciarSesion/hooks/useIp';

const Bienvenida = ({ apiUrl, setEstaEnSesion, usuarioEnSesion, setUsuarioEnSesion, token, setToken, setEstaEnAjustes, setEstaEnContacto, setEstaEnPregFrec }) => {
    const navigate = useNavigate();
    const [habilitadoSEU, setHabilitadoSEU] = useState(false) // SEU: SALA de ESPERA de USUARIO
    const [tokenVencido, setTokenVencido] = useState(false) // estado de error, si el token se venció, esto da true
    const ip = useIp()

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    // se ejecutara si el usuario pulsa el boton de CERRAR SESION
    const handleCloseSesion = async () => {
        navigate('/inicioSesion')
        setEstaEnSesion(false)
        const data = usuarioEnSesion?.TipoUsuario === 'Estandar' ? { idUsuario: usuarioEnSesion.usuarioComun.Id } : { idProfesional: usuarioEnSesion.Id }
        await axios.put(`${apiUrl}/api/plataforma_medica/usuarios/usuario/logout`, data)
        setUsuarioEnSesion(null)
        setToken(null)
        window.localStorage.removeItem("DoctorFy");
    }

    // funcionalidad que trae los turnos existentes que esten en sala de espera, y si algun turno tiene como IdPaciente
    // solicitante al que inicio sesion, que le habilite el boton de sala de espera
    const traerTurnosPaciente = async () => {
        const response = await salaEsperaPacServices.getTurnosPacSalaEspera(token)
        if (response.error) {
            setHabilitadoSEU(false); // deshabilito Sala Espera Usuario
            if (response.error === "TOKEN VENCIDO.") { // si la sesion caduco, que habilite el error en true
                setTokenVencido(true)
                return;
            }
        } else { // si la sesion no caduco, que consulte el array de turnos traidos del backend, si alguno coincide su IdPaciente con el 
            // Id del usuario logueado, se habilita el boton de sala de espera
            if (response?.some(turno => turno.IdPaciente === usuarioEnSesion.usuarioComun.Id)) {
                setHabilitadoSEU(true); // habilito Sala Espera Usuario
            } else {
                setHabilitadoSEU(false) // deshabilito Sala Espera Usuario
            }
        }
    }

    // efecto para traer los turnos existentes en sala de espera
    useEffect(() => {
        if (usuarioEnSesion !== null) {
            if (usuarioEnSesion?.TipoUsuario === 'Estandar') {
                traerTurnosPaciente()
            }
        }
    }, [usuarioEnSesion])

    return (
        <>
            {/* SI HAY UN USUARIO LOGUEADO Y LA SESIÓN NO CADUCO RENDERIZA LO QUE ESTA DENTRO DEL ?, 
            si alguna de las condiciones no se cumple, renderiza el msj de volver a login  */}
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    {/* SI ES UN USUARIO ESTANDAR  */}
                    {usuarioEnSesion?.TipoUsuario === 'Estandar' && (
                        <>
                            <div className="row">
                                <div className="col s12 m6 offset-m3">
                                    <div className="logo-container center">
                                        <img src={logoPTM} alt="Logo PTM" width="500" />
                                    </div>
                                    <h4 className="center-align" style={{ color: 'blue', margin: 0 }}>Tablero de Comando de</h4>
                                    <h5 className="center-align" style={{ margin: 0 }}>{usuarioEnSesion?.usuarioComun?.Nombre?.toUpperCase()} {usuarioEnSesion?.usuarioComun?.Apellido?.toUpperCase()}</h5>


                                    <div className="button-container">
                                        <div className="center-align" style={{ marginLeft: '8px', marginRight: '8px' }}>
                                            <button className="btn btn-floating blue darken-4" onClick={() => navigate('/solicitudTurnoPac')}>
                                                <i className="material-icons">person</i>
                                            </button>
                                            <p className='solicitarTurnop'>Solicitar Turno</p>
                                            <p className='solicitarTurnop'>para mi</p>
                                        </div>
                                        <div className="center-align" style={{ marginLeft: '8px', marginRight: '8px' }}>
                                            <button className="btn btn-floating black" onClick={() => navigate('/solicitudTurnoPacParaOtro')}>
                                                <i className="material-icons">people</i>
                                            </button>
                                            <p className='solicitarTurnop'>Solicitar Turno</p>
                                            <p className='solicitarTurnop'>para otro</p>
                                        </div>
                                        <div className="center-align" style={{ marginLeft: '8px', marginRight: '8px' }}>
                                            <button className="btn btn-floating blue darken-4" onClick={() => navigate('/misOfertasTurnoPac')}>
                                                <i className="material-icons">local_offer</i>
                                            </button>
                                            <p className='solicitarTurnop'>Mis ofertas</p>
                                            <p className='solicitarTurnop'>de Turno</p>
                                        </div>
                                    </div>

                                    <div className="button-container" style={{ margin: '16px' }}>
                                        <div className="center-align" style={{ marginLeft: '8px', marginRight: '8px' }}>
                                            <button className="btn btn-floating black" onClick={() => navigate('/misTurnosConfirmadosPac')}>
                                                <i className="material-icons">verified</i>
                                            </button>
                                            <p className='solicitarTurnop'>Mis Turnos</p>
                                            <p className='solicitarTurnop'>Confirmados</p>
                                        </div>
                                        <div className="center-align">
                                            <button className="btn btn-floating blue darken-4" onClick={() => navigate('/salaEsperaPac')} disabled={!habilitadoSEU}>
                                                <i className="material-icons">chair</i>
                                            </button>
                                            <p className='solicitarTurnop'>Sala de</p>
                                            <p className='solicitarTurnop'>Espera</p>
                                        </div>
                                    </div>

                                    <div className="button-container">
                                        <div className="center-align">
                                            <button className="btn btn-floating black" onClick={() => navigate('/misTurnosPac')}>
                                                <i className="material-icons">question_answer</i>
                                            </button>
                                            <p className='solicitarTurnop'>Mis</p>
                                            <p className='solicitarTurnop'>consultas</p>
                                        </div>
                                    </div>

                                    <div className='col s12 center' id='btn-close-sesion' style={{ marginTop: '8px' }}>
                                        <button className='btn waves-effect waves-light' type='button' onClick={() => handleCloseSesion()}>
                                            CERRAR SESION
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {/* SI ES UN MEDICO EL QUE SE LOGUEO */}
                    {usuarioEnSesion?.TipoUsuario === 'Medico' && (
                        <>
                            <div className="row">
                                <div className="col s12 m6 offset-m3">
                                    <div className="logo-container center">
                                        <img src={logoPTM} alt="Logo PTM" width="200" />
                                    </div>
                                    <h4 className="center-align" style={{ color: 'blue', margin: 0 }}>Tablero de Comando del Profesional</h4>
                                    <h5 className="center-align" style={{ margin: 0 }}>{usuarioEnSesion?.Nombre?.toUpperCase()} {usuarioEnSesion?.Apellido?.toUpperCase()}</h5>


                                    <div className="button-container">
                                        <div className="center-align" style={{ marginLeft: '8px', marginRight: '8px' }}>
                                            <button className="btn btn-floating blue darken-4" onClick={() => navigate('/misTurnosProf')}>
                                                <i className="material-icons">person_add</i>
                                            </button>
                                            <p className='solicitarTurnop'>Mis solicitudes</p>
                                            <p className='solicitarTurnop' >de Turno</p>
                                        </div>
                                        <div className="center-align" style={{ marginLeft: '8px', marginRight: '8px' }}>
                                            <button className="btn btn-floating black" onClick={() => navigate('/misTurnosAceptadosProf')}>
                                                <i className="material-icons">calendar_month</i>
                                            </button>
                                            <p className='solicitarTurnop'>Asignar</p>
                                            <p className='solicitarTurnop'>Turno</p>
                                        </div>
                                        <div className="center-align" style={{ marginLeft: '8px', marginRight: '8px' }}>
                                            <button className="btn btn-floating blue darken-4" onClick={() => navigate('/misTurnosConfirmadosProf')}>
                                                <i className="material-icons">verified</i>
                                            </button>
                                            <p className='solicitarTurnop'>Mis Turnos</p>
                                            <p className='solicitarTurnop'>confirmados</p>
                                        </div>
                                    </div>

                                    <div className="button-container" style={{ margin: '16px' }}>
                                        <div className="center-align">
                                            <button className="btn btn-floating black" onClick={() => navigate('/misConsultasTomadasProf')}>
                                                <i className="material-icons">question_answer</i>
                                            </button>
                                            <p className='solicitarTurnop'>Mis</p>
                                            <p className='solicitarTurnop'>consultas</p>
                                        </div>
                                        <div className="center-align">
                                            <button className="btn btn-floating blue darken-4" onClick={() => navigate('/salaEsperaProf')}>
                                                <i className="material-icons">chair</i>
                                            </button>
                                            <p className='solicitarTurnop'>Sala de</p>
                                            <p className='solicitarTurnop'>Espera</p>
                                        </div>
                                    </div>

                                    <div className="button-container">
                                        <div className="center-align">
                                            <button className="btn btn-floating black" onClick={() => navigate('/turnosAConfirmarProf')}>
                                                <i className="material-icons">verified</i>
                                            </button>
                                            <p className='solicitarTurnop'>Confirmar</p>
                                            <p className='solicitarTurnop'>Turno</p>
                                        </div>
                                    </div>

                                    <div className='col s12 center' id='btn-close-sesion' style={{ marginTop: '8px' }}>
                                        <button className='btn waves-effect waves-light' type='button' onClick={() => handleCloseSesion()}>
                                            CERRAR SESION
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            ) : (
                // Si el token expiró o no hay un usuario en sesión
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    );
};

export { Bienvenida };