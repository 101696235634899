import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { SessionExpired } from '../../../components/SessionExpired/SessionExpired';
import logoPTM from "../../../../images/newLogoPTM.jpeg"
import { useForm } from 'react-hook-form';
import "./SeleccionMedioPago.css"
import { mediosPagoServices } from './services/medios_pago.service';
import useIp from '../../../PantallaInicial/components/IniciarSesion/hooks/useIp';
import { useEvento } from '../../../../hooks/useEvento';


const SeleccionMedioPago = ({
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
}) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [tokenVencido, setTokenVencido] = useState(false)
    const [mediosPago, setMediosPago] = useState(null)
    const [nextUrl, setNextUrl] = useState(null)

    const ip = useIp()
    const { handleCampoLlego, campoLlego, crearE } = useEvento()

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    // efecto para obtener los medios de pago
    useEffect(() => {
        if (token !== null) {
            const traerMediosPago = async () => {
                setMediosPago(await mediosPagoServices.getMediosPago(token))
            }
            traerMediosPago()
        }
    }, [token])

    const handleBack = () => {
        navigate('/pagos1/' + id)
        if (campoLlego !== null) {
            crearE({ id_tipo: 61, id_resultado: 2, ip_user: ip, id_form: 12, id_campo_llego: campoLlego, id_usuario: usuarioEnSesion?.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion?.usuarioComun ? usuarioEnSesion.Id : null }, token)
            crearE({ id_tipo: 62, id_resultado: 2, ip_user: ip, id_form: 12, id_campo_llego: campoLlego, id_usuario: usuarioEnSesion?.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion?.usuarioComun ? usuarioEnSesion.Id : null }, token)
        } else {
            crearE({ id_tipo: 62, id_resultado: 1, ip_user: ip, id_usuario: usuarioEnSesion?.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion?.usuarioComun ? usuarioEnSesion.Id : null }, token)
        }
    }

    const goToPayScreen = () => {
        crearE({ id_tipo: 61, id_resultado: 1, ip_user: ip, id_form: 12, id_campo_llego: campoLlego, id_usuario: usuarioEnSesion?.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion?.usuarioComun ? usuarioEnSesion.Id : null }, token)
        crearE({ id_tipo: 63, id_resultado: 1, ip_user: ip, id_form: 12, id_campo_llego: campoLlego, id_usuario: usuarioEnSesion?.usuarioComun ? usuarioEnSesion.usuarioComun.Id : null, id_profesional: !usuarioEnSesion?.usuarioComun ? usuarioEnSesion.Id : null }, token)
        if (nextUrl === 1) {
            navigate(`/pagos/${nextUrl}/${id}`)
        }
        else {
            navigate(`/pagos/2/${nextUrl}/${id}`)
        }
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className='row'>
                        <div className='col s12 center'>
                            <div className="logo-container center">
                                <img src={logoPTM} alt="Logo PTM" width="500" />
                            </div>
                            <h5 id="header-form-registro">PAGAR TURNO</h5>
                        </div>

                        <div className='col s4'></div>

                        <div className="col s4">
                            <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="card_number">Seleccioná Medio de Pago</label>
                            <select onChangeCapture={() => handleCampoLlego(73)} style={{ fontWeight: "bold", marginBottom: "1px" }} className='validate browser-default' required onChange={(e) => setNextUrl(parseInt(e.target.value))}>
                                <option style={{ fontWeight: "bold" }} value="" disabled selected>Selecciona una opción</option>
                                {mediosPago && mediosPago.map((medioPago, index) => {
                                    return (
                                        <option style={{ fontWeight: "bold" }} key={index} value={medioPago.id}>{medioPago.nombre?.toUpperCase()}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className='col s4'></div>

                        <div className="col s12 center" style={{ marginBottom: "80px", marginTop: "20px" }}>
                            <button className='btn waves-effect waves-light' type="button" style={{ margin: "8px" }} onClick={() => goToPayScreen()} >
                                PAGAR
                                <i className='material-icons right large'>account_box</i>
                            </button>

                            <button
                                className='btn waves-effect waves-light'
                                type="button"
                                onClick={handleBack}
                                style={{ margin: "8px" }}
                            >
                                VOLVER
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    )
}

export { SeleccionMedioPago }