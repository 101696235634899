import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PreguntasSIyNO } from '../PreguntasSIyNO/PreguntasSIyNO.js';
import logoPTM from "../../../../images/newLogoPTM.jpeg";

// este componente basicamente es una pregunta de SI o NO de si es profesional de la salud o no, y dependiendo de eso, le aparecera un formulario de registro u otro
const EsMedicoOPaciente = ({
    esMedico,
    setEsMedico,
}) => {
    const navigate = useNavigate();

    // si el usuario hace click en el boton volver, se ejecuta esta funcion
    const handleBack = () => {
        setEsMedico(null)
        if (typeof document !== 'undefined') {
            if (document.referrer === "https://doctorfy.com.ar/") {
                navigate("https://doctorfy.com.ar/");
            } else {
                navigate("/")
            }
        }
    }

    return (
        <div className='container-center'>
            <div className="row" id="contenedor-img-registro">
                <div className="img-container">
                    <img src={logoPTM} alt="Logo Alimenta Salud" />
                </div>
            </div>

            <div id="formulario-seleccionador">
                <form className="col s6 ">
                    {esMedico === null ? (
                        <PreguntasSIyNO pregunta="Sos Profesional de la Salud?" handleRespuesta={(respuesta) => setEsMedico(respuesta)} />
                    ) : (
                        <div className='col s12 center'>
                            <button className="btn waves-effect waves-light" type="button" name="action" id="continue" onClick={() => { esMedico ? navigate("/medico") : navigate("/paciente") }}>CONTINUAR
                                <i className="material-icons right">send</i>
                            </button>
                        </div>
                    )}
                </form>
            </div>

            <div className='col s12 center'>
                <button
                    className='btn waves-effect waves-light'
                    type="button"
                    onClick={handleBack}
                    style={{ marginTop: "12px" }}
                >
                    VOLVER
                </button>
            </div>
        </div>
    )
};

export { EsMedicoOPaciente }